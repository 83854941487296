import React from 'react'
import { Box, Text, Flex, UnorderedList, ListItem } from '@chakra-ui/react'
import { RenewalDetails } from 'types/Renewals'
import { combineFirstNameLastName } from 'lib/utils'
import { Link } from 'react-router-dom'

const RenewRentalConfirmationDetails: React.FC<
  React.PropsWithChildren<{
    renewalDetails: RenewalDetails
  }>
> = ({ renewalDetails }) => {
  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  } as const
  return (
    <Box my={'2'}>
      <Text
        fontSize={'md'}
        color={'gray.400'}
        borderBottom={'1px'}
        borderColor={'gray.300'}
        mb={'1'}
      >
        Renewal Details
      </Text>
      <Flex
        px='4'
        py='2'
        mt={3}
        color={'gray.900'}
        backgroundColor='yellow.100'
        gap={1}
        fontSize={'md'}
        direction='column'
      >
        <Text fontSize={'md'} color={'gray.900'}>
          {renewalDetails.fullAddress}
        </Text>
        <Text fontSize={'md'} color={'gray.900'}>
          <b>Original Lease Date: </b>
          {new Date(renewalDetails.originalLeaseDate).toLocaleDateString('en-US', options)}
        </Text>
        <Text fontSize={'md'} color={'gray.900'}>
          <b>Landlord: </b>
          {renewalDetails.landlordName}
        </Text>
        <Text>
          <b>New Rent:</b> ${renewalDetails.newLeaseRent} + ${renewalDetails.optionPremium}{' '}
          investment
        </Text>
        <Text>
          <b>New Term: </b>
          {renewalDetails.extensionMonthsInDigits}{' '}
          {renewalDetails.extensionMonthsInDigits > 1 ? 'months' : 'month'}
        </Text>
        <Text fontSize={'md'}>
          <b>Renewal Dates: </b>
          {renewalDetails.newLeaseStartDate.toLocaleDateString('en-US', options)} -{' '}
          {renewalDetails.newLeaseEndDate.toLocaleDateString('en-US', options)}
        </Text>
        <Text>
          <b>Signers: </b>
        </Text>
        <UnorderedList>
          {renewalDetails.signers.map(({ user }) => (
            <ListItem key={user?.id}>
              {' '}
              <Link to={{ pathname: `/users/${user?.id}` }} target='_blank'>
                {user ? combineFirstNameLastName(user) : ''}
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
        {renewalDetails.addOns.length > 0 && (
          <>
            <Text>
              <b>Add-Ons: </b>
            </Text>
            <UnorderedList>
              {renewalDetails.addOns
                .sort((a, b) => a.liability_type_name.localeCompare(b.liability_type_name))
                .map((addOn) => (
                  <ListItem key={addOn.liability_type_name}>
                    {addOn.liability_type_name} (${addOn.price}/month)
                  </ListItem>
                ))}
            </UnorderedList>
          </>
        )}
      </Flex>
    </Box>
  )
}

export default RenewRentalConfirmationDetails
