import {
  Button,
  Heading,
  Link,
  ListItem,
  Select,
  Stack,
  Text,
  UnorderedList,
  useToast
} from '@chakra-ui/react'
import { PendingRenewal } from '@homevest/types/renewals'
import { formatISODate } from '@homevest/utils/dates'
import { RENEWAL_CANCELATION_REASONS } from '@homevest/utils/renewals'
import axios from 'lib/axios'
import PropertyDisplay from 'components/RenewalManager/SelectedRentalView/PropertyDisplay'
import React, { useState } from 'react'
import { startCase } from 'lodash'
import { GreenCheck, RedXIcon } from 'components/TailwindUIToolkit/StyledComponents'
import { ReunderwriteModal } from '../Reunderwrites/ReunderwriteModal'

export const SelectedPendingRenewalView = ({
  renewal,
  onAction
}: {
  renewal?: PendingRenewal
  onAction?: () => void
}) => {
  if (!renewal) {
    return null
  }

  return (
    <div className='py-4flex min-h-fit flex-col gap-4 rounded-xl border'>
      <div className='flex h-full flex-grow flex-col divide-y lg:flex-row lg:divide-x lg:divide-y-0'>
        {[
          <PropertyDisplay {...{ ...renewal, ...renewal.home_details }} />,
          <RenewalInfo renewal={renewal} />,
          <Actions renewal={renewal} onAction={onAction} />
        ].map((item) => (
          <div className='flex w-full flex-col gap-4 px-4 py-4 lg:w-1/3 lg:flex-grow'>{item}</div>
        ))}
      </div>
    </div>
  )
}

const Subheading = ({ children }: { children: React.ReactNode }) => (
  <Text size='sm' decoration='underline'>
    {children}
  </Text>
)

const RenewalInfo = ({ renewal }: { renewal: PendingRenewal }) => {
  return (
    <Stack>
      <Heading size='md'>Renewal Info</Heading>
      <Stack>
        <Subheading>Renewal ID</Subheading>
        <Text>{renewal.renewal_id}</Text>
      </Stack>
      <Stack>
        <Subheading>Status</Subheading>
        <Text>{startCase(renewal.status)}</Text>
      </Stack>
      <Stack>
        <Subheading>Active Offers</Subheading>
        <UnorderedList pl='4'>
          {renewal.renewal_offers.map((offer) => (
            <ListItem key={offer.id}>
              {offer.is_month_to_month ? 'Month-to-month' : `${offer.term} months`} @ {offer.price}{' '}
              / month
            </ListItem>
          ))}
        </UnorderedList>
        <div className='italic'>Offered on {formatISODate(renewal.offered_at)}</div>
      </Stack>
      {renewal.selected_renewal_offer && (
        <Stack>
          <Subheading>Selected Offer</Subheading>
          <Text>
            {renewal.selected_renewal_offer.is_month_to_month
              ? 'Month-to-month'
              : `${renewal.selected_renewal_offer.term} months`}{' '}
            @ {renewal.selected_renewal_offer.price} / month
          </Text>
          <div className='italic'>
            Selected on {formatISODate(renewal.renewal_option_selected_at)}
          </div>
        </Stack>
      )}
      {renewal.signers && (
        <Stack>
          <Subheading>Signers</Subheading>
          <UnorderedList pl='4'>
            {renewal.signers.map((signer) => (
              <ListItem key={signer.id}>
                {signer.status === 'signed' ? <GreenCheck /> : <RedXIcon />} {signer.name} |{' '}
                {signer.role}
              </ListItem>
            ))}
          </UnorderedList>
        </Stack>
      )}
    </Stack>
  )
}

const Actions = ({ renewal, onAction }: { renewal: PendingRenewal; onAction?: () => void }) => {
  const [cancelationReason, setCancelationReason] = useState('')
  const [reunderwriteModalOpen, setReunderwriteModalOpen] = useState(false)
  const toast = useToast({ position: 'top-right' })
  const cancelRenewal = async () => {
    try {
      await axios.post(`/admin/renewals/${renewal.renewal_id}/cancel`, {
        cancelation_reason: cancelationReason
      })
      onAction?.()
      toast({ status: 'success', title: 'Renewal cancelled' })
    } catch (e) {
      toast({ status: 'error', title: 'Failed to cancel renewal' })
    }
  }
  const adminSignRequired = renewal.signers?.some(
    (signer) => signer.role === 'admin' && signer.status !== 'signed'
  )
  return (
    <Stack>
      <Heading size='md'>Actions</Heading>
      <Button
        colorScheme='blackAlpha'
        variant='outline'
        onClick={() => setReunderwriteModalOpen(true)}
      >
        Reunderwrite
      </Button>
      <ReunderwriteModal
        rentalId={renewal.rental_id}
        isOpen={reunderwriteModalOpen}
        close={() => setReunderwriteModalOpen(false)}
      />
      {adminSignRequired && (
        <Stack>
          <Subheading>Sign</Subheading>
          <Text>Go to rent-roll to make any outstanding agreement signatures.</Text>
          <Text opacity={0.5}>
            (Look for the <em>Pending Renewals</em> panel)
          </Text>
          <Link w='full' href={`/rent-roll/${renewal.rental_id}`}>
            <Button w='full' colorScheme='whatsapp'>
              Sign
            </Button>
          </Link>
        </Stack>
      )}
      <Stack>
        <Subheading>Cancelation</Subheading>
        <Text>
          This will deactivate the renewal and remove any renewal options/actions from the
          renter&apos;s view. To proceed, please input a cancelation reason:
        </Text>
        <Select value={cancelationReason} onChange={(e) => setCancelationReason(e.target.value)}>
          <option value={''}>Select a cancelation reason</option>
          {Object.values(RENEWAL_CANCELATION_REASONS).map((reason) => (
            <option value={reason}>{startCase(reason)}</option>
          ))}
        </Select>
        <Button colorScheme='red' isDisabled={!cancelationReason} onClick={cancelRenewal}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  )
}
