import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import startCase from 'lodash/startCase'
import {
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  HStack,
  UnorderedList,
  ListItem,
  Select,
  useToast
} from '@chakra-ui/react'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { CASHOUT_STATUSES } from '@homevest/utils/cashouts'
import axios from 'lib/axios'

const { PENDING, MAINTENANCE_CHARGEBACKS_FINALIZED, UTILITIES_FINALIZED } = CASHOUT_STATUSES

const UndoCashoutModal = ({
  rentalId,
  isOpen,
  onClose
}: {
  rentalId: string
  isOpen: boolean
  onClose: () => void
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const toast = useToast()
  const history = useHistory()
  const closeAndReset = () => {
    onClose()
    setSelectedStatus('')
  }

  return (
    <Modal isOpen={isOpen} onClose={closeAndReset} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Are you sure you want to undo this cashout?</ModalHeader>
        <ModalBody>
          <Text>Clicking this button will:</Text>
          <UnorderedList>
            <ListItem>Revert the status of the cashout to the selected status.</ListItem>
            <ListItem>Delete all adjustments and write-offs from the rent roll ledger.</ListItem>
            <ListItem>
              <span className='font-semibold'>Attempt</span> to reverse the cashout journal entry in
              Buildium. If this journal entry fails to post,{' '}
              <span className='font-semibold'>you are responsible</span> for fixing Buildium.
            </ListItem>
          </UnorderedList>
          <Text mt={6} mb={2}>
            If you want to proceed, select the cashout status you would like to revert to:
          </Text>
          <Select
            w='100%'
            size='md'
            justifySelf='center'
            // textAlign='center'
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value=''>Select a status</option>
            <option value={PENDING}>{startCase(PENDING)}</option>
            <option value={MAINTENANCE_CHARGEBACKS_FINALIZED}>
              {startCase(MAINTENANCE_CHARGEBACKS_FINALIZED)}
            </option>
            <option value={UTILITIES_FINALIZED}>{startCase(UTILITIES_FINALIZED)}</option>
          </Select>
        </ModalBody>
        <ModalFooter>
          <HStack w='100%' justifyContent='space-between'>
            <Button w='100%' onClick={closeAndReset}>
              Cancel
            </Button>
            <Button
              w='50%'
              colorScheme='red'
              isDisabled={!selectedStatus}
              onClick={async () => {
                try {
                  const status = selectedStatus
                  await axios.post(`/admin/rentals/${rentalId}/undo_cashout`, { status })
                  toast({
                    title: `Cashout reverted to status ${startCase(status)}!`,
                    status: 'success',
                    position: 'top',
                    duration: 5000
                  })
                  closeAndReset()
                  history.replace(`/rent-roll/${rentalId}`)
                } catch (err: any) {
                  toast({
                    title: `Error reverting cashout: ${err.message}`,
                    status: 'error',
                    position: 'top',
                    duration: 5000
                  })
                }
              }}
            >
              <ArrowUturnLeftIcon height={24} />
              <Text ml={2}>Undo Cashout</Text>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UndoCashoutModal
