import React from 'react'
import {
  Modal,
  Link,
  Text,
  Button,
  HStack,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react'

const CancelAgreement: React.FC<{
  onClose: () => void
  cancelAgreement: () => Promise<void>
  isCanceling: boolean
  buildiumLeaseId: number | null
}> = ({ onClose, cancelAgreement, isCanceling, buildiumLeaseId }) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Cancel Agreement</ModalHeader>
        <ModalBody>
          <Text>Are you sure you want to cancel this agreement?</Text>
          {buildiumLeaseId && (
            <Text className='mt-2'>
              After canceling this agreement, you will need to either terminate or delete the{' '}
              <Link
                href={`https://upandup.managebuilding.com/manager/app/rentroll/${buildiumLeaseId}/summary`}
                isExternal={true}
                color='blue.500'
                className='font-semibold'
              >
                associated Buildium lease
              </Link>
              .
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={async () => {
                await cancelAgreement()
                onClose()
              }}
              colorScheme='red'
              isDisabled={isCanceling}
              isLoading={isCanceling}
            >
              Cancel Agreement
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CancelAgreement
