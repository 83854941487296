import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { rentalApplications } from '@homevest/utils'

import { HeaderText, Modal, Dropdown, Button, SpanText } from 'components/Toolkit'
import { startCase } from 'lodash'

const { RENTAL_APPLICATION_CANCELATION_REASONS } = rentalApplications

const CANCELATION_REASONS = Object.values(RENTAL_APPLICATION_CANCELATION_REASONS).map((value) => ({
  label: startCase(value),
  value
}))

function CancelApplication({ close, cancel, isCanceling, buildiumLeaseId }) {
  const [cancelReason, setCancelReason] = useState('')

  return (
    <Modal close={close} hasCloseButton>
      <HeaderText style={{ marginTop: '40px', marginBottom: '20px' }} size='h4'>
        Why do you want to cancel?
      </HeaderText>
      <Dropdown
        onChange={setCancelReason}
        options={CANCELATION_REASONS}
        label='Reason'
        value={cancelReason}
      />
      {buildiumLeaseId && (
        <SpanText>
          After canceling this application, you will need to either terminate or delete the{' '}
          <a
            href={`https://upandup.managebuilding.com/manager/app/rentroll/${buildiumLeaseId}/summary`}
            target='_blank'
            rel='noopener noreferrer'
            className='font-semibold text-blue-500'
          >
            associated Buildium lease
          </a>
          .
        </SpanText>
      )}
      <Button
        onClick={async () => {
          await cancel(cancelReason)
          close()
        }}
        style={{ width: '100%', marginTop: '30px' }}
        disabled={!cancelReason || isCanceling}
        isLoading={isCanceling}
      >
        Cancel Application
      </Button>
    </Modal>
  )
}

CancelApplication.propTypes = {
  cancel: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

export default memo(CancelApplication)
