import React from 'react'
import { Heading, Stack } from '@chakra-ui/react'
import { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'

import { Sidepeek, Link } from 'ui'
import {
  NotesGrid,
  CreateNoteButtonWithModal,
  LeftAlignedDataList
} from 'components/TailwindUIToolkit'
import { formatProjectTitle } from 'lib/construction-projects'
import { formatUTCDate, formatDateWithDistance } from 'lib/date-time'
import { formatMoney } from 'lib/numbers'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'

const ProjectsSidepeek = ({
  project,
  isOpen,
  onClose
}: {
  project: ConstructionProjectApiResponse | null
  isOpen: boolean
  onClose: () => void
}) => {
  if (!project) {
    return null
  }

  const projectDisplayData = [
    {
      label: 'Type',
      value: mapContentToBadge(project.type)
    },
    {
      label: 'Status',
      value: mapContentToBadge(project.status)
    },
    {
      label: 'General Contractor',
      value: project.general_contractor?.name ?? '-'
    },
    {
      label: 'Last status change',
      value: formatDateWithDistance(project.last_status_change_at, 'MMM do, yyyy h:mm a')
    },
    {
      label: 'Market',
      value: project.market ?? '-'
    },
    {
      label: 'Address',
      value: `${project.address}, ${project.city}, ${project.state}, ${project.zip}`
    },
    {
      label: 'Start Date',
      value: project.start_date ? formatUTCDate(project.start_date, 'MMM do, yyyy') : '-'
    },
    {
      label: 'End Date',
      value: project.end_date ? formatUTCDate(project.end_date, 'MMM do, yyyy') : '-'
    },

    {
      label: 'Estimated Cost',
      value: formatMoney(project.estimated_cost, 2, '$')
    },
    {
      label: 'Actual Cost',
      value: formatMoney(project.actual_cost, 2, '$')
    }
  ]

  const rentalDisplayData = [
    {
      label: 'Prior Rental',
      value: project.prior_rental?.id ? (
        <Link useRouter isExternal showIcon to={`/rent-roll/${project.prior_rental?.id}`}>
          Rent Roll
        </Link>
      ) : (
        '-'
      )
    },
    {
      label: 'Move Out Date',
      value: project.prior_rental?.move_out_date
        ? formatUTCDate(project.prior_rental.move_out_date, 'MMM do, yyyy')
        : '-'
    },
    {
      label: 'Next Rental',
      value: project.next_rental?.id ? (
        <Link useRouter isExternal showIcon to={`/rent-roll/${project.next_rental?.id}`}>
          Rent Roll
        </Link>
      ) : (
        '-'
      )
    },
    {
      label: 'Move In Date',
      value: project.next_rental?.occupancy_date
        ? formatUTCDate(project.next_rental?.occupancy_date, 'MMM do, yyyy')
        : '-'
    }
  ]

  const homeDisplayData = [
    {
      label: 'Rent Ready Date',
      value: project.rent_ready_date ? formatUTCDate(project.rent_ready_date, 'MMM do, yyyy') : '-'
    },
    {
      label: 'Tour Ready Date',
      value: project.tour_ready_date ? formatUTCDate(project.tour_ready_date, 'MMM do, yyyy') : '-'
    }
  ]

  return (
    <Sidepeek
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Link
          useRouter
          isExternal
          color='black'
          textDecoration={undefined}
          to={`/construction/projects/${project.id}`}
        >
          {formatProjectTitle(project.type, project.address, project.start_date)}
        </Link>
      }
    >
      <div className='py-4'>
        <Stack spacing={7}>
          <Stack>
            <Heading size='md'>Details</Heading>
            <Stack spacing={5}>
              <LeftAlignedDataList data={projectDisplayData} />
              <LeftAlignedDataList data={rentalDisplayData} />
              <LeftAlignedDataList data={homeDisplayData} />
            </Stack>
          </Stack>
          <Stack spacing={3}>
            <div className='flex flex-row justify-between'>
              <Heading size='md'>Notes</Heading>
              <CreateNoteButtonWithModal
                resourceId={project.id}
                resourceType='construction_project'
              />
            </div>
            <div className='h-[80vh]'>
              <NotesGrid resourceIds={[project.id]} columnIdsToExclude={['type']} />
            </div>
          </Stack>
        </Stack>
      </div>
    </Sidepeek>
  )
}

export default ProjectsSidepeek
