import React, { useState } from 'react'
import { GridApi } from 'ag-grid-community'
import { useQuery } from '@tanstack/react-query'
import { BuildiumResidentCenterUsersResponse } from '@homevest/types/buildium'
import { Center, Spinner, Button } from '@chakra-ui/react'

import axios from 'lib/axios'
import BasicPageLayout from 'components/TailwindUIToolkit/Layouts/BasicPageLayout'
import BuildiumResidentCenterUsersGrid from './Grid'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

const Buildium = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getBuildiumResidentCenterUsers'],
    queryFn: async () => {
      const response = await axios.get<BuildiumResidentCenterUsersResponse>(
        'admin/buildium/resident_center_users'
      )
      return response.data.data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  if (isLoading || !data) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <BasicPageLayout
      tabTitle='Resident Center Users'
      heading='Buildium Resident Center Users'
      topRightElement={
        <Button
          rightIcon={<ChevronRightIcon height={20} />}
          variant='ghost'
          size='md'
          as='a'
          href='https://upandup.managebuilding.com/manager/app/communication/resident-site/users'
        >
          Go to Resident Center
        </Button>
      }
    >
      <div className='p-4'>
        <BuildiumResidentCenterUsersGrid
          data={data}
          isFetching={isFetching}
          gridApi={gridApi}
          setGridApi={setGridApi}
        />
      </div>
    </BasicPageLayout>
  )
}

export default Buildium
