import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { capabilities } from '@homevest/utils'
import { StoreState } from 'store'
import get from 'lodash/get'

import { formatMoney } from 'lib/numbers'
import { generatePaymentMethodDescription } from 'lib/payments'
import axios from 'lib/axios'
import { Button, HStack, Text } from '@chakra-ui/react'
import { hasCapability } from 'lib/admin-perms'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { LineItemsOwedFragment } from 'graphql/generated'
import ChargeModal from './ChargeModal'

const { CUSTOMER_CHARGER } = capabilities.CAPABILITY_TYPES

type LineItem = {
  amount: number
  liabilityTypeId: string
  name: string
}

export default function LineItemsOwed({ rental }: { rental: LineItemsOwedFragment }) {
  const [lineItemsOwed, setLineItemsOwed] = useState<LineItem[]>([])
  const [isCustomChargeModalOpen, setIsCustomChargeModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const admin = useSelector<StoreState, any>((state) => state.admin)

  const defaultPaymentMethod = rental.user.payment_methods.find((pm) => pm.is_default)
  const canChargePayment = hasCapability(admin, CUSTOMER_CHARGER) && defaultPaymentMethod

  useEffect(() => {
    const loadLineItemsOwed = async (rentalId: string) => {
      const res = await axios.get(`/admin/rentals/${rentalId}/line_items_owed`)

      setLineItemsOwed(res.data)
    }

    loadLineItemsOwed(rental.id)
  }, [rental])

  const handleSubmit = async () => {
    if (!lineItemsOwed || !lineItemsOwed.length) {
      window.alert('No line items owed')
      return
    }
    if (!canChargePayment) {
      window.alert('You do not have permissions to charge')
      return
    }

    if (
      window.confirm(
        `Are you sure you want to charge this customer for:\n\n${lineItemsOwed.reduce(
          (acc, lineItem) => `${acc}${lineItem.name}: ${formatMoney(lineItem.amount, 2, '$')}\n`,
          ''
        )}\nReversing is a pain in the ass ⚠.`
      )
    ) {
      try {
        setIsSaving(true)

        await axios.post(`/admin/rentals/${rental.id}/charge`)
      } catch (err) {
        const error = err as any
        const detailMessage = get(error, 'response.data.message')

        window.alert(detailMessage || error.message)
        setIsSaving(false)
      }
    }
  }

  if (!lineItemsOwed.length) {
    return null
  }

  return (
    <ContentSectionCard title={'Upcoming Payment Breakdown'} padding>
      <div>
        <Text>Note: (charges - completed payments and credits) per type</Text>
        <Text>
          Does not contain payment/credit information (negative values) if there is not a
          corresponding charge.{' '}
        </Text>
        {lineItemsOwed.map(({ amount, liabilityTypeId, name }) => (
          <Text key={liabilityTypeId}>
            {name}: ${formatMoney(amount)}
          </Text>
        ))}

        <HStack justifyContent='space-between'>
          <Button
            size='xs'
            colorScheme='teal'
            onClick={handleSubmit}
            disabled={isSaving || !defaultPaymentMethod}
          >
            {generatePaymentMethodDescription(defaultPaymentMethod!)}
          </Button>
          <HStack>
            <Button
              size='xs'
              colorScheme='teal'
              onClick={() => setIsCustomChargeModalOpen(true)}
              disabled={isSaving || !canChargePayment}
            >
              Custom Charge
            </Button>
          </HStack>
        </HStack>
        {!defaultPaymentMethod && (
          <div>⚠️ There is no payment method for the user so charging will not work.</div>
        )}
      </div>
      <>
        {canChargePayment && (
          <ChargeModal
            rentalId={rental.id}
            close={() => setIsCustomChargeModalOpen(false)}
            isOpen={isCustomChargeModalOpen}
          />
        )}
      </>
    </ContentSectionCard>
  )
}
