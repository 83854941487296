import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Stack, Input, Spinner, Center, Button } from '@chakra-ui/react'
import { GridApi, ColDef, ICellRendererParams } from 'ag-grid-community'
import { BuildiumResidentCenterUserResponse } from '@homevest/types/buildium'
import { FaLink } from 'react-icons/fa'

import { BaseGrid } from 'components/TailwindUIToolkit'
import SetFilter from 'components/AgGrid/filters/SetFilter'
import MagnifyingGlassPlusIcon from '@heroicons/react/24/outline/MagnifyingGlassPlusIcon'

const BuildiumResidentCenterUsersGrid = ({
  data = [],
  isFetching,
  gridApi,
  setGridApi
}: {
  data?: BuildiumResidentCenterUserResponse[]
  isFetching: boolean
  gridApi: GridApi | null
  setGridApi: (api: GridApi) => void
}) => {
  const [noteModal, setNoteModal] = useState(false)
  const [noteModalData, setNoteModalData] = useState('')
  const [buildiumResidentCenterUsersSearch, setBuildiumResidentCenterUsersSearch] = useState('')

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api)
  }

  useEffect(() => {
    gridApi?.setQuickFilter(buildiumResidentCenterUsersSearch)
    gridApi?.onFilterChanged()
  }, [gridApi, buildiumResidentCenterUsersSearch])

  return (
    <>
      <Stack gap={4}>
        <Input
          type='text'
          value={buildiumResidentCenterUsersSearch}
          onChange={(e) => setBuildiumResidentCenterUsersSearch(e.target.value)}
          placeholder='Search...'
        />
        <div className='h-[80vh] rounded border'>
          {isFetching ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <BaseGrid
              rowData={data}
              columns={[
                ...buildiumResidentCenterUserColumns,
                {
                  colId: 'most_recent_note',
                  headerName: 'Recent Note',
                  width: 120,
                  cellRenderer: (
                    params: ICellRendererParams<BuildiumResidentCenterUserResponse>
                  ) => (
                    <Button
                      as={MagnifyingGlassPlusIcon}
                      variant={'ghost'}
                      size='xs'
                      padding='3px 5px'
                      onClick={() => {
                        setNoteModal(true)
                        setNoteModalData(
                          params.data?.rental?.most_recent_note || 'No notes found for rental'
                        )
                      }}
                    ></Button>
                  ),
                  resizable: true
                }
              ]}
              onGridReady={onGridReady}
              defaultColDefOverrides={{
                wrapHeaderText: true,
                autoHeaderHeight: true
              }}
            />
          )}
        </div>
      </Stack>
      <Modal toggle={() => setNoteModal(false)} isOpen={noteModal}>
        <ModalHeader>Most Recent Note</ModalHeader>
        <ModalBody>
          <p>{noteModalData}</p>
        </ModalBody>
      </Modal>
    </>
  )
}

export const buildiumResidentCenterUserColumns: ColDef<BuildiumResidentCenterUserResponse>[] = [
  {
    colId: 'id',
    headerName: 'ID',
    field: 'id',
    hide: true
  },
  {
    colId: 'rental_address',
    headerName: 'Rental',
    field: 'rental.address',
    cellRenderer: (params: { data: BuildiumResidentCenterUserResponse; value: string }) => {
      return (
        <a
          href={`/rent-roll/${params.data?.rental?.id}`}
          target='_blank'
          rel='noopener noreferrer'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <FaLink style={{ marginRight: '6px' }} />
          {params.value}
        </a>
      )
    }
  },
  {
    colId: 'name',
    headerName: 'Name',
    field: 'name',
    valueGetter: (params) => {
      return `${params.data?.buildium_user_first_name} ${params.data?.buildium_user_last_name}`
    }
  },
  {
    colId: 'primary_tenant',
    headerName: 'Is Primary?',
    field: 'user.role',
    valueGetter: (params) => {
      return params.data?.user.role === 'primary' ? 'Yes' : 'No'
    }
  },
  {
    colId: 'email',
    headerName: 'Email',
    field: 'user.email'
  },
  {
    colId: 'resident_center_status',
    headerName: 'Resident Center Status',
    field: 'resident_center_status',
    filter: SetFilter
  },
  {
    colId: 'is_auto_pay_enabled',
    headerName: 'Auto Pay Enabled',
    field: 'is_auto_pay_enabled',
    valueGetter: (params) => {
      return params.data?.is_auto_pay_enabled ? 'Yes' : 'No'
    },
    filter: SetFilter
  }
]

export default BuildiumResidentCenterUsersGrid
