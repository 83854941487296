import React, { useState, useMemo, useContext } from 'react'
import moment from 'moment'
import { documents } from '@homevest/utils'

import axios from 'lib/axios'
import { RentRollDocumentsFragment } from 'graphql/generated'
import { ActionButton, ContentSectionCard } from 'components/TailwindUIToolkit'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, useToast, Button } from '@chakra-ui/react'
import { UploadDocumentPanel } from 'components/Documents/UploadDocumentPanel'
import { Sidepeek } from 'ui'
import { RentalDetailsContext } from './RentalDetailsContext'
const { DOCUMENT_TYPES } = documents

export default function Documents({ rental }: { rental: RentRollDocumentsFragment }) {
  // const [docUrlMap, setDocUrlMap] = useState<{ [id: string]: string }>({})
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const toast = useToast()
  const documentList = useMemo(() => {
    const innerDocumentList = rental.documents

    // Selecting latest completed rental_application
    const rental_application_documents =
      rental.rental_application?.document_agreements[0]?.documents || []

    innerDocumentList.push(...rental_application_documents)

    // Select all documents agreements related to the rental
    const rentalAgreementDocuments = (rental.agreements || []).flatMap(
      (agreement) => agreement.documents
    )

    innerDocumentList.push(...rentalAgreementDocuments)

    const dedupedDocList = innerDocumentList.reduce((acc, doc) => {
      if (!acc.find((d) => d.id === doc.id)) {
        acc.push(doc)
      }
      return acc
    }, [] as typeof innerDocumentList)

    return dedupedDocList
  }, [rental])
  let { rentalData } = useContext(RentalDetailsContext)

  const tenant = rentalData?.rental_users.find((ru) => ru.role === 'primary')?.user
  const tenantName = tenant ? `${tenant.first_name} ${tenant.last_name}` : 'Unknown Tenant'
  const isRentalComplete = ['complete', 'canceled'].includes(rental.status)
  const uploadButton = (
    <ActionButton size='xs' onClick={() => setDrawerOpen(true)}>
      + Document
      <Sidepeek isOpen={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <UploadDocumentPanel
          fixedStatus={isRentalComplete ? 'final' : undefined}
          generateName={isRentalComplete ? (type) => `${type} - ${tenantName}` : undefined}
          setClosed={() => {
            setDrawerOpen(false)
            toast({
              title: 'Document uploaded',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top'
            })
          }}
          resourceId={rental.id}
          resourceType='rentals'
          typeMap={{
            [DOCUMENT_TYPES.W9]: 'W9',
            [DOCUMENT_TYPES.RENTER_INSURANCE]: "Renter's Insurance",
            [DOCUMENT_TYPES.VOIDED_CHECK]: 'Voided Check',
            [DOCUMENT_TYPES.LEASE]: 'Lease',
            [DOCUMENT_TYPES.LEASE_TERMINATION]: 'Lease Termination',
            [DOCUMENT_TYPES.PURCHASE_OPTION_AGREEMENT_MEMORANDUM]: 'POA Memorandum',
            [DOCUMENT_TYPES.PURCHASE_OPTION_AGREEMENT_AMENDMENT]: 'POA Amendment',
            [DOCUMENT_TYPES.ASSIGNMENT_OF_LEASE_AND_PURCHASE_OPTION_AGREEMENT]:
              'Assignment of Lease and POA',
            [DOCUMENT_TYPES.CONSENT_TO_ASSIGNMENT_OF_LEASE_AND_PURCHASE_OPTION_AGREEMENT]:
              'Consent to Assignment of Lease and POA',
            [DOCUMENT_TYPES.LEASE_AMENDMENT]: 'Lease Amendment',
            [DOCUMENT_TYPES.MOVE_IN_STATEMENT]: 'Move In Statement',
            [DOCUMENT_TYPES.APPLICATION_PACKET]: 'Application Packet'
          }}
        />
      </Sidepeek>
    </ActionButton>
  )
  return (
    <ContentSectionCard collapsable={false} title={'Documents'} action={uploadButton}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th>Uploaded</Th>
              <Th>Reviewed</Th>
              <Th>Reviewed By</Th>
              <Th>Notes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {documentList?.map((doc) => (
              <Tr key={doc.id}>
                <Td>
                  <Button
                    variant='link'
                    onClick={async () => {
                      const url = await axios
                        .get(`/admin/documents/${doc.id}/url`)
                        .then((resp) => resp.data.url)
                      if (url) {
                        window.open(url, '_blank')
                      } else {
                        toast({
                          title: 'Failed to fetch document URL',
                          status: 'error',
                          duration: 3000,
                          isClosable: true,
                          position: 'top-right'
                        })
                      }
                    }}
                  >
                    {doc.friendly_name}
                  </Button>
                </Td>
                <Td>{mapContentToBadge(doc.status)}</Td>
                <Td>{doc.created_at ? moment(doc.created_at).format('YYYY-MM-DD') : 'N/A'}</Td>
                <Td>{doc.reviewed_at ? moment(doc.reviewed_at).format('YYYY-MM-DD') : 'N/A'}</Td>
                <Td>{doc.admin ? `${doc.admin?.first_name} ${doc.admin?.last_name}` : 'N/A'}</Td>
                <Td>{doc.review_notes || 'N/A'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </ContentSectionCard>
  )
}
