import React, { Suspense } from 'react'
// @ts-ignore
import Tree from 'react-d3-tree'
import { Tooltip } from '@chakra-ui/react'

type RawNodeDatum = {
  name: string
  attributes?: Record<string, string>
  children?: RawNodeDatum[]
}

const Lineage: React.FC = () => {
  const lineage = {
    'Michael Wong': 'The Father',
    'Needham Hurst': 'The Mother',
    'Andrew Winter': 'The Step-Mother',
    'Ryan Brown': 'The Step-Father',
    'Melanie Rothman': 'The Aunt',
    'Benedict Wong': 'The Uncle',
    'Ramon Qiu': 'The Uncle',
    'Kai Lin Hii': 'The Cousin',
    'Kevin Yan': 'The Older Brother',
    'Jilliane Avila': 'The Middle Sister',
    'Thomas Asnake': 'The Middle Brother',
    'Jocelyn Thai': 'A Twin',
    'Serra Kocoglu': 'A Twin'
  }
  const hoverText = {
    'Michael Wong': 'Karaoke & Gadget Obsessed',
    'Needham Hurst': 'Tarot Reading Burrito Eating',
    'Andrew Winter': 'Weekly Skiing Sweetgreen Loyalist',
    'Ryan Brown': 'Always On The Move',
    'Melanie Rothman': 'Sweet But Needs to Move Back to NY',
    'Benedict Wong': 'Always Screaming on His Phone Motorcyclist',
    'Ramon Qiu': 'Orders Expensive Things',
    'Kai Lin Hii': 'Brings Us Sweets',
    'Kevin Yan': 'We All Rely On Him',
    'Jilliane Avila': 'Organized Joe & the Juice Addict',
    'Thomas Asnake': 'Can Sweet Talk Anyone',
    'Jocelyn Thai': 'Night Owl Hacker',
    'Serra Kocoglu': 'Early Bird Finance'
  }

  const treeData: RawNodeDatum = {
    name: 'Welcome to the Up&Up Family!',
    children: [
      {
        name: 'The Parents',
        children: [
          {
            name: 'Ramon Qiu',
            attributes: { role: 'Uncle', desc: 'Orders Expensive Things' }
          },
          {
            name: 'Ryan Brown',
            attributes: { role: 'Step-Father', desc: 'Always On The Move' }
          },
          {
            name: 'Needham Hurst',
            attributes: { role: 'Mother', desc: 'Tarot Reading Burrito Eating' }
          },
          {
            name: 'Michael Wong',
            attributes: { role: 'Father', desc: 'Karaoke & Gadget Obsessed' }
          },
          {
            name: 'Andrew Winter',
            attributes: { role: 'Step-Mother', desc: 'Weekly Skiing Sweetgreen Loyalist' }
          },
          {
            name: 'Melanie Rothman',
            attributes: { role: 'Aunt', desc: 'Sweet But Needs to Move Back to NY' }
          },
          {
            name: 'Benedict Wong',
            attributes: { role: 'Uncle', desc: 'Always Screaming on His Phone Motorcyclist' }
          }
        ]
      },
      {
        name: 'The Children',
        children: [
          {
            name: 'Kevin Yan',
            attributes: { role: 'Older Brother', desc: 'We All Rely On Him' }
          },
          {
            name: 'Jilliane Avila',
            attributes: { role: 'Middle Sister', desc: 'Organized Joe & the Juice Addict' }
          },
          {
            name: 'Thomas Asnake',
            attributes: { role: 'Middle Brother', desc: 'Can Sweet Talk Anyone' }
          },
          {
            name: 'Jocelyn Thai',
            attributes: { role: 'Twin', desc: 'Night Owl Hacker' }
          },
          {
            name: 'Serra Kocoglu',
            attributes: { role: 'Twin', desc: 'Early Bird Finance' }
          },
          {
            name: 'Kai Lin Hii',
            attributes: { role: 'Cousin', desc: 'Brings Us Sweets' }
          }
        ]
      }
    ]
  }

  const nodeWidth = 120
  const nodeHeight = 80

  const renderNode = ({ nodeDatum, foreignObjectProps }: any) => (
    <g>
      <foreignObject {...foreignObjectProps}>
        <Tooltip
          label={hoverText[nodeDatum.name as keyof typeof hoverText]}
          hasArrow
          placement='top'
        >
          <div
            style={{
              width: nodeWidth - 10,
              height: nodeHeight - 10,
              backgroundColor: '#fff',
              border: '2px solid #ccc',
              borderRadius: '5px',
              padding: '5px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              cursor: 'pointer',
              textAlign: 'center'
            }}
          >
            <div style={{ width: '100%', textAlign: 'center' }}>{nodeDatum.name}</div>
            <div style={{ width: '100%', fontSize: '10px', color: '#666', textAlign: 'center' }}>
              {lineage[nodeDatum.name as keyof typeof lineage]}
            </div>
          </div>
        </Tooltip>
      </foreignObject>
    </g>
  )

  return (
    <div
      className='lineage-container'
      style={{ width: '100vw', height: '100vh', overflow: 'auto' }}
    >
      <div style={{ width: '100%', height: 'calc(100vh - 60px)' }}>
        <Suspense fallback={<div>Loading tree...</div>}>
          <Tree
            data={treeData}
            dimensions={{
              width: window.innerWidth,
              height: window.innerHeight - 60
            }}
            nodeSize={{
              x: nodeWidth * 2,
              y: nodeHeight * 2
            }}
            renderCustomNodeElement={(rd3tProps) =>
              renderNode({
                ...rd3tProps,
                foreignObjectProps: {
                  width: nodeWidth,
                  height: nodeHeight,
                  x: -nodeWidth / 2,
                  y: -nodeHeight / 2
                }
              })
            }
            orientation='vertical'
          />
        </Suspense>
      </div>
    </div>
  )
}

export default Lineage
