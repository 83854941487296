import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Text,
  Link
} from '@chakra-ui/react'

import { addDays, addMonths, lastDayOfMonth } from 'date-fns'

import { useRentalForRenewalQuery } from 'graphql/generated'
import {
  calculateRenewalRentIncrease,
  RENEWAL_RENT_INCREASES
} from 'constants/renewal-rent-increases'
import { getAddressForRental, getLlc, getRentalLeaseSigners, getRentForRental } from 'lib/rentals'

import RenewRentalModalForm from './RenewRentalModalForm'
import RenewRentalModalDetails from './RenewRentalModalDetails'
import RenewRentalConfirmation from './RenewRentalConfirmation'
import { AgreementSigners } from 'types/FullRental'
import { Reunderwrite } from './ReunderwriteModalContent'

const RenewRentalModal: React.FC<
  React.PropsWithChildren<{
    rentalId: string
    isOpen: boolean
    setOpen: (closed: boolean) => void
  }>
> = ({ rentalId, isOpen, setOpen }) => {
  const [{ data }] = useRentalForRenewalQuery({
    variables: { id: rentalId }
  })

  const rental = data?.rentals_by_pk
  const [display, setDisplay] = useState<'renewal' | 'reunderwrite'>('renewal')
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false)
  const [optionPremium, setOptionPremium] = useState(0)
  const [notes, setNotes] = useState('')
  const [term, setTerm] = useState(12)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [rent, setRent] = useState(0)
  const [baseRent, setBaseRent] = useState(0)
  const [key, setKey] = useState(0)
  const [showOtherTerm, setShowOtherTerm] = useState<boolean>(false)
  const [newAgreementSigners, setAgreementSigners] = useState<AgreementSigners>()
  const [addOns, setAddOns] = useState<
    {
      price: number
      liability_type_name: string
    }[]
  >([])

  const canSubmit = term && rent && (!showOtherTerm || (startDate && endDate))

  useEffect(() => {
    if (data?.rentals_by_pk) {
      const newRental = data.rentals_by_pk
      const agreementSigners = getRentalLeaseSigners(newRental) || []
      const { rent: baseRent } = getRentForRental(newRental)
      const addOns = newRental.rental_add_ons
        .filter(
          (addOn) =>
            addOn.end_date === null || (startDate && new Date(startDate) < new Date(addOn.end_date))
        )
        .map((addOn) => ({
          price: Number(addOn.price),
          liability_type_name: addOn.liability_type.name
        }))
      setBaseRent(baseRent)
      setAgreementSigners(agreementSigners)
      setAddOns(addOns)
    }
  }, [rental, data])

  const leaseEndDate = new Date(rental?.rental_agreement_histories[0]?.ends_at)

  useEffect(() => {
    // rerender form when term changes to show new rent but not when other term is selected
    if (term && RENEWAL_RENT_INCREASES[term] && !showOtherTerm) {
      setRent(calculateRenewalRentIncrease(baseRent, term))
      setKey(key + 1)
    } else if (term && showOtherTerm) {
      setStartDate(addDays(leaseEndDate, 1))
      setEndDate(lastDayOfMonth(addMonths(leaseEndDate, term)))
    }
  }, [term, baseRent, showOtherTerm])

  if (!rental) {
    return <Spinner />
  }

  const address = getAddressForRental(rental)
  const { optionPremium: baseOptionPremium } = getRentForRental(rental)

  const renewalContent = (
    <ModalContent>
      <ModalHeader>
        <Text>Create a Custom Renewal</Text>
      </ModalHeader>
      <Divider />
      <ModalCloseButton />
      <ModalBody display='flex' flexDir='column'>
        <Text mb={4} as='i'>
          Use this modal to directly issue a renewal lease, without allowing the tenant to pick
          their desired term. Note that creating a renewal in this way will NOT trigger the
          automated renewal email. Use the new{' '}
          <Link as={RouterLink} color='teal' to='/properties/renewal-manager'>
            Renewal Manager
          </Link>{' '}
          to allow the tenant to select a renewal offer on the Web portal.
        </Text>
        <Button onClick={() => setDisplay('reunderwrite')}>Reunderwrite</Button>
        <RenewRentalModalDetails
          rent={baseRent}
          optionPremium={baseOptionPremium}
          address={address}
          rental={rental}
          agreementSigners={getRentalLeaseSigners(rental)}
          addOns={addOns}
        />
        <RenewRentalModalForm
          key={key}
          rent={rent}
          leaseEndDate={leaseEndDate}
          setRent={setRent}
          optionPremium={optionPremium}
          setOptionPremium={setOptionPremium}
          term={term}
          setTerm={setTerm}
          notes={notes}
          setNotes={setNotes}
          showOtherTerm={showOtherTerm}
          setShowOtherTerm={setShowOtherTerm}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          agreementSigners={newAgreementSigners ?? []}
          setAgreementSigners={setAgreementSigners}
        />
      </ModalBody>

      <ModalFooter>
        <Box w={'full'} pb='2'>
          <Flex>
            <Button
              colorScheme='red'
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              disabled={!canSubmit}
              colorScheme='green'
              onClick={() => {
                setOpen(false)
                setConfirmationIsOpen(true)
              }}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </ModalFooter>
    </ModalContent>
  )

  const reunderwriteContent = (
    <Reunderwrite rentalId={rental.id} goBack={() => setDisplay('renewal')} />
  )

  return (
    <>
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => setOpen(false)} size='xl'>
        <ModalOverlay />
        {display === 'renewal' && renewalContent}
        {display === 'reunderwrite' && reunderwriteContent}
      </Modal>
      <RenewRentalConfirmation
        renewalDetails={{
          fullAddress: address,
          landlordName: getLlc(rental)?.name,
          signers: newAgreementSigners ?? [],
          originalLeaseDate: new Date(rental.occupancy_date),
          extensionMonthsInDigits: term,
          newLeaseEndDate:
            showOtherTerm && endDate ? endDate : lastDayOfMonth(addMonths(leaseEndDate, term)),
          newLeaseStartDate: showOtherTerm && startDate ? startDate : addDays(leaseEndDate, 1),
          newLeaseRent: rent,
          optionPremium,
          notes,
          addOns: addOns
        }}
        isOpen={confirmationIsOpen}
        setOpen={setConfirmationIsOpen}
        goBack={setOpen}
      />
    </>
  )
}

export default RenewRentalModal
