import React, { useContext, useState } from 'react'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { Button, Input, ErrorText, TextArea } from 'components/Toolkit'
import { useCreateRecurringCreditMutation } from 'graphql/generated'
import { RentalDetailsContext } from '../RentalDetailsContext'
import LiabilityTypesDropdown from '../Dropdowns/LiabilityTypesDropdown'

const IssueRecurringCreditForm: React.FC<
  React.PropsWithChildren<{
    onSubmit: () => void
    rentalId: string
  }>
> = ({ onSubmit, rentalId }) => {
  const firstOfNextMonth = moment().add(1, 'months').startOf('month')
  const [totalAmount, setTotalAmount] = useState('')
  const [startDate, setStartDate] = useState(firstOfNextMonth.format('YYYY-MM-DD'))
  const [numMonths, setNumMonths] = useState(1)
  const [liabilityType, setLiabilityType] = useState<{ id: string }>()
  const [note, setNote] = useState('')
  const [isMoveInCredit, setIsMoveInCredit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [{ fetching, error }, createMoveInCredit] = useCreateRecurringCreditMutation()
  let { rentalData, liabilityTypes } = useContext(RentalDetailsContext)

  const rentLiabilityType = liabilityTypes.find((lt) => lt.name === 'Rent')

  const isDateValid = () => {
    if (!startDate) {
      return false
    }
    if (!moment(startDate).isValid()) {
      return false
    }
    return (
      moment(startDate).isSameOrAfter(firstOfNextMonth) ||
      moment(startDate).isSame(rentalData?.occupancy_date)
    )
  }

  const isValid =
    isDateValid() &&
    totalAmount &&
    !Number.isNaN(totalAmount) &&
    note &&
    note.trim() &&
    liabilityType?.id

  const handleSubmit = async () => {
    if (!isValid) {
      return
    }
    setIsSaving(true)
    const result = await createMoveInCredit({
      rental_id: rentalId,
      start_date: startDate,
      num_months: numMonths,
      total_amount: totalAmount,
      notes: note,
      liability_type_id: liabilityType?.id,
      move_in_credit: isMoveInCredit
    })
    setIsSaving(false)
    if (result.error === undefined) {
      setTotalAmount('')
      setStartDate('')
      setNote('')
      onSubmit()
    }
  }

  const toggleIsMoveInCredit = () => {
    if (isMoveInCredit) {
      setIsMoveInCredit(false)
      setLiabilityType(undefined)
      setNote('')
    } else {
      setIsMoveInCredit(true)
      setLiabilityType({ id: rentLiabilityType?.id })
      setNote('Move-In Credit')
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <Input
          label='Is this a move-in credit?'
          type='checkbox'
          value={isMoveInCredit}
          onChange={toggleIsMoveInCredit}
          hasError={false}
        />
        <Input
          label='Price'
          type='number'
          onChange={setTotalAmount}
          value={totalAmount}
          placeholder='Enter the total amount of the credit'
          required
          min='0'
          hasError={Number(totalAmount) <= 0}
          disabled={isSaving || fetching}
        />
        <Input
          label='Start Date - either the first of the next month or the occupancy date'
          type='date'
          onChange={setStartDate}
          value={startDate}
          placeholder='Either the first of the next month or the occupancy date'
          required
          disabled={fetching || isSaving}
          hasError={!isDateValid()}
        />
        <Input
          label='Months - number of months to spread the credit over'
          type='number'
          onChange={setNumMonths}
          value={numMonths}
          placeholder='Enter the number of months to apply the credit for'
          required
          min='1'
          hasError={Number(numMonths) <= 0}
          disabled={isSaving || fetching}
        />

        <LiabilityTypesDropdown
          disabled={fetching || isSaving}
          onSelect={setLiabilityType}
          value={liabilityType}
        />
        <TextArea
          label='Note'
          type='textarea'
          rows={5}
          onChange={setNote}
          value={note}
          placeholder='Enter the reason for giving the credit'
          required
          disabled={fetching || isSaving}
          hasError={!note}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={handleSubmit}
            disabled={!isValid || fetching || isSaving}
          >
            Issue Recurring Credit
          </Button>
          {error !== undefined && <ErrorText>{error.message}</ErrorText>}
        </div>
      </React.Suspense>
    </>
  )
}

export default IssueRecurringCreditForm
