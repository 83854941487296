import React, { FunctionComponent } from 'react'
import { Box, Menu, MenuButton, MenuList, MenuItem, Text, HStack, Icon } from '@chakra-ui/react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { auth } from 'lib/firebase'
import { useAuthContext } from 'context/auth'

const ProfileMenu: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { firebaseUser } = useAuthContext()

  const lineage = {
    'Michael Wong': 'Karaoke & Gadget Obsessed Father',
    'Needham Hurst': 'Tarot Reading Burrito Eating Mother',
    'Andrew Winter': 'Weekly Skiing Sweetgreen Loyalist Step-Mother',
    'Ryan Brown': 'Always On The Move Step-Father',
    'Melanie Rothman': 'Sweet Aunt That Needs to Move Back to NY',
    'Benedict Wong': 'Always Screaming on His Phone Motorcyclist Uncle',
    'Ramon Qiu': 'Orders Expensive Things Uncle',
    'Kai Lin Hii': 'Brings Us Sweets Cousin',
    'Kevin Yan': 'We All Rely On Him Older Brother',
    'Jilliane Avila': 'Organized Joe & the Juice Addicted Middle Sister',
    'Thomas Asnake': 'Can Sweet Talk Anyone Middle Brother',
    'Jocelyn Thai': 'Night Owl Hacker Twin',
    'Serra Kocoglu': 'Early Bird Finance Twin'
  }

  const displayName = firebaseUser?.displayName || 'Anonymous'
  const lineageRelation = lineage[displayName as keyof typeof lineage]

  return (
    <Box px={8}>
      {firebaseUser && (
        <div>
          <Menu>
            <MenuButton>
              <HStack spacing={2}>
                <Icon as={UserCircleIcon} boxSize={5} color='gray.600' />
                <Text>
                  {displayName}
                  {lineageRelation && ` (${lineageRelation})`}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={async () => {
                  await auth.signOut()
                  window.location.reload()
                }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      )}
    </Box>
  )
}

export default ProfileMenu
