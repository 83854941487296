"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACH_SUCCESS_CODES = exports.ACH_RETURN_CODES = exports.PAYMENT_METHODS = exports.AUTO_CHARGE_RETRY_DAYS_OF_MONTH = exports.AUTO_CHARGE_DAY_OF_MONTH = exports.AUTO_CHARGE_HOUR_UTC = exports.PAYMENT_LIFECYCLE = exports.PAYMENT_STATUSES = exports.PAYMENT_CATEGORIES = exports.PAYMENT_METHOD_STATUSES = exports.PAYMENT_METHOD_TYPES = void 0;
exports.PAYMENT_METHOD_TYPES = {
    ACH: 'ach',
    CARD: 'card',
    CHECK: 'check'
};
exports.PAYMENT_METHOD_STATUSES = {
    ACTIVE: 'active',
    PENDING_REAUTH: 'pending_reauth',
    PENDING: 'pending',
    INACTIVE: 'inactive'
};
exports.PAYMENT_CATEGORIES = {
    ADD_ON: 'add_on',
    INITIAL_PAYMENT: 'initial_payment',
    LATE_FEE: 'late_fee',
    MAINTENANCE_CHARGEBACK: 'maintenance_chargeback',
    MISCELLANEOUS_FEE: 'miscellaneous_fee',
    NSF_FEE: 'nsf_fee',
    OPTION_PREMIUM: 'option_premium',
    RENT: 'rent',
    SECURITY_DEPOSIT: 'security_deposit',
    UTILITIES: 'utilities'
};
exports.PAYMENT_STATUSES = {
    DISPUTED: 'disputed',
    FAILED: 'failed',
    HOLD: 'hold',
    INTENDED: 'intended',
    NSF_FAILED: 'nsf_failed',
    PENDING: 'pending',
    PROCESSING: 'processing',
    REFUNDED: 'refunded',
    SUCCEEDED: 'succeeded'
};
exports.PAYMENT_LIFECYCLE = {
    QUEUED: 'queued',
    INITIATED: 'initiated',
    INITIALLY_CLEARED: 'initially_cleared',
    CLEARED: 'cleared',
    NOT_CLEARED: 'not_cleared'
};
exports.AUTO_CHARGE_HOUR_UTC = 15; // 10 AM EST
exports.AUTO_CHARGE_DAY_OF_MONTH = 1;
exports.AUTO_CHARGE_RETRY_DAYS_OF_MONTH = [3, 8, 13];
/** Warning: to be deprecated */
exports.PAYMENT_METHODS = exports.PAYMENT_METHOD_TYPES;
// Standard ACH Return Codes
// https://www.moderntreasury.com/learn/ach-return-code-reference
exports.ACH_RETURN_CODES = {
    R01: 'Insufficient Funds',
    R02: 'Account Closed',
    R03: 'No Account / Unable to Locate Account',
    R04: 'Invalid Account Number Structure',
    R05: 'Unauthorized Consumer Debit using Corporate SEC Code',
    R06: 'ODFI Requested Return',
    R07: 'Customer Revoked Authorization',
    R08: 'Payment Stopped',
    R09: 'Uncollected Funds',
    R10: 'Originator not known and/or not authorized to Debit Receiver’s Account',
    R11: 'Customer advises not within Authorization Terms',
    R12: 'Account Sold to Another DFI',
    R13: 'Invalid ACH Routing No.',
    R14: 'Representative Payee Deceased',
    R15: 'Beneficiary / Account Holder Deceased',
    R16: 'Account Frozen / Returned per OFAC',
    R17: 'File Record Edit Criteria / Suspicious Entry with Invalid Account No. / Return of Improperly-Initiated Reversal',
    R18: 'Improper Effective Date',
    R19: 'Amount Field Error',
    R20: 'Non-Transaction Account',
    R21: 'Invalid Company ID',
    R22: 'Invalid Individual ID',
    R23: 'Receiver Refused Credit',
    R24: 'Duplicate Entry',
    R25: 'Addenda Error',
    R26: 'Mandatory Field Error',
    R27: 'Trace Number Error',
    R28: 'Routing No. Check Digit Error',
    R29: 'Not Authorized by Corporate Customer',
    R30: 'RDFI not in Check Truncation Program',
    R31: 'Permissible Return (CCD and CTX only)',
    R32: 'RDFI Non-Settlement',
    R33: 'Return of XCK',
    R34: 'Limited Participation DFI',
    R35: 'Improper Debit',
    R36: 'Improper Credit',
    R37: 'Source Document Presented',
    R38: 'Stop Payment on Source Document',
    R39: 'Improper Source Document',
    R40: 'Return of ENR',
    R41: 'Invalid Transaction Code',
    R42: 'Routing No. / Check Digit Error',
    R43: 'Invalid DFI Account No.',
    R44: 'Invalid Individual ID No.',
    R45: 'Invalid Individual / Company Name',
    R46: 'Invalid Representative Payee Indicator',
    R47: 'Duplicate Enrollment',
    R50: 'State Law Affecting RCK Acceptance',
    R51: 'Ineligible / Improper Item Related to RCK',
    R52: 'Stop Payment on Item Related to RCK',
    R53: 'Item and RCK Presented for Payment',
    R61: 'Misrouted Return',
    R62: 'Erroneous / Reversing Debit',
    R67: 'Duplicate Return',
    R68: 'Untimely Return',
    R69: 'Field Error',
    R70: 'Permissible Return Not Accepted / Not Requested by ODFI',
    R71: 'Misrouted Dishonored Return',
    R72: 'Untimely Dishonored Return',
    R73: 'Timely Original Return',
    R74: 'Corrected Return',
    R75: 'Return Not Duplicate',
    R76: 'No Errors Found',
    R77: 'Non-Acceptance of R62',
    R80: 'IAT Coding Error',
    R81: 'Non-Participant in IAT Program',
    R82: 'Invalid Foreign RDFI Identification',
    R83: 'Foreign RDFI Unable to Settle',
    R84: 'Not Processed by Gateway',
    R85: 'Incorrectly Coded Outbound Int’l Payment'
};
exports.ACH_SUCCESS_CODES = {
    S01: 'Success'
};
