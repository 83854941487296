import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import startCase from 'lodash/startCase'
import { addMonths, startOfMonth } from 'date-fns'
import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  HStack,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { rentalUsers } from '@homevest/utils'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import {
  CashoutSidebarFragment,
  TenantsFragment,
  useUpupUpdateUserDisableSelfInitiatedPaymentsMutation,
  useUpdateRentalUserCreditCardEnabledAtMutation
} from 'graphql/generated'
import { getContactInfo } from 'lib/users'
import { EllipsisHorizontal } from 'styled-icons/heroicons-solid'

const { isAutoChargeDisabledAt } = rentalUsers

export default function Tenants({ rental }: { rental: CashoutSidebarFragment }) {
  const [isSaving, setIsSaving] = useState(false)
  const [
    { fetching: disableSelfInitiatedPaymentFetching, error: disableSelfInitiatedPaymentError },
    setDisableSelfInitiatedPayment
  ] = useUpupUpdateUserDisableSelfInitiatedPaymentsMutation()

  const [
    { fetching: enableCreditCardFetching, error: enableCreditCardError },
    setCreditCardEnabled
  ] = useUpdateRentalUserCreditCardEnabledAtMutation()

  const updateCreditCardEnabledAt = async (rentalUserId: string, enabled: boolean) => {
    setIsSaving(true)
    try {
      await setCreditCardEnabled({
        creditCardEnabledAt: enabled ? new Date() : null,
        rentalUserId: rentalUserId
      })
    } finally {
      setIsSaving(false)
    }
  }

  const updateDisableSelfInitiatedPayment = async (
    userId: string,
    disableSelfInitiatedPayment: boolean
  ) => {
    setIsSaving(true)
    try {
      await setDisableSelfInitiatedPayment({
        status: disableSelfInitiatedPayment,
        userId: userId
      })
    } finally {
      setIsSaving(false)
    }
  }

  const isDisabled = enableCreditCardFetching || disableSelfInitiatedPaymentFetching || isSaving

  function getRentalUser(user: { id: string; __typename: 'users' }) {
    return rental.rental_users.find((ru): boolean => {
      return ru.user_id === user.id
    })
  }

  function isAutoChargeEnabled(user: TenantsFragment['rental_users'][number]): boolean {
    if (!rental.auto_charge) {
      return false
    }

    if (!user) {
      return false
    }

    if (!user.auto_charge_disabled_at) {
      return true
    }

    const nextMonth: Date = startOfMonth(addMonths(new Date(), 1))

    // This function from HAPI is backwards looking so we go forward looking by going into the future and then negating.
    // Makes total sense amiright?
    return !isAutoChargeDisabledAt(user.auto_charge_disabled_at, nextMonth)
  }

  type RentalUser = CashoutSidebarFragment['rental_users'][number]
  function isRentalUser(tenant: any): tenant is RentalUser {
    return 'role' in tenant
  }
  type Occupant = CashoutSidebarFragment['rental_occupants'][number]
  function isOccupant(tenant: any): tenant is Occupant {
    return 'occupant_number' in tenant
  }

  function copyData(tenantData: Array<RentalUser | Occupant>) {
    const formattedTenants = tenantData
      .filter((t) => {
        if (isRentalUser(t)) return t.deactivated_at === null
        else if (isOccupant(t)) return true
        else return false
      })
      .map((t) => {
        let copyString = ''
        if (isRentalUser(t)) {
          const { user, role } = t
          copyString = `${user?.first_name} ${user?.last_name} - ${startCase(
            role
          )} - ${getContactInfo('email', user?.user_contact_details)} - ${getContactInfo(
            'phone',
            user?.user_contact_details
          )}`
        } else if (isOccupant(t)) {
          copyString = `${t.occupant_name} - Occupant #${t.occupant_number} - ${t.occupant_relation}`
        }
        return copyString
      })
      .join('\n')
    navigator.clipboard.writeText(formattedTenants)
  }

  const sortedRentalUsers = rental.rental_users.sort((a, b) =>
    a.role === 'primary' ? -1 : b.role === 'primary' ? 1 : 0
  )
  const occupants = rental.rental_occupants
  // action button that copies tenant data to clipboard
  const copyTenantData = () => copyData([...sortedRentalUsers, ...occupants])

  return (
    <ContentSectionCard
      padding
      title={'Tenants'}
      action={
        <Button
          size='xs'
          colorScheme='teal'
          onClick={(evt) => {
            evt.stopPropagation()
            copyTenantData()
          }}
        >
          Copy Tenants
        </Button>
      }
    >
      <div>
        {(disableSelfInitiatedPaymentError || enableCreditCardError) && (
          <Alert status='error'>
            <AlertIcon />
            There was an error processing your request
          </Alert>
        )}
      </div>
      <div>
        <List>
          {sortedRentalUsers.map((rentalUser) => {
            const { user, role, deactivated_at: deactivatedAt } = rentalUser
            return (
              <ListItem key={user?.id} style={{ marginBottom: '.5rem' }}>
                <HStack justify='space-between'>
                  <VStack>
                    <Link to={`/users/${user?.id}`} target='_blank'>
                      {!deactivatedAt && (
                        <b>
                          {user?.first_name} {user?.last_name} - {startCase(role)} -{' '}
                          {!deactivatedAt && (
                            <>
                              {user && isAutoChargeEnabled(rentalUser) ? (
                                <Tooltip label='Auto-charge enabled for upcoming month.'>
                                  <span>💰</span>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  label={`Auto charge disabled at ${
                                    getRentalUser(user!)?.auto_charge_disabled_at || 'N/A'
                                  }`}
                                >
                                  <span>⚠️</span>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {rentalUser?.credit_card_enabled_at && (
                            <Tooltip label='Card can be used as method of payment.'>
                              <span>💳</span>
                            </Tooltip>
                          )}
                          {user?.disable_self_initiated_payments && (
                            <Tooltip label='User cannot make payments'>
                              <span>🚫</span>
                            </Tooltip>
                          )}
                        </b>
                      )}
                      {deactivatedAt && (
                        <s>
                          {user?.first_name} {user?.last_name} - {startCase(role)}
                        </s>
                      )}
                      <br />
                      {getContactInfo('email', user?.user_contact_details)}{' '}
                      {getContactInfo('phone', user?.user_contact_details)}
                    </Link>
                  </VStack>
                  <Menu closeOnSelect={false}>
                    <MenuButton as={Button}>
                      <EllipsisHorizontal size={20} />
                    </MenuButton>
                    <Portal>
                      <MenuList>
                        <MenuItem>
                          {user && !deactivatedAt && (
                            <div>
                              <Checkbox
                                isChecked={rentalUser?.credit_card_enabled_at || false}
                                isDisabled={isDisabled}
                                onChange={(e) => {
                                  return updateCreditCardEnabledAt(rentalUser?.id, e.target.checked)
                                }}
                              >
                                Enable adding card as method of payment
                              </Checkbox>
                            </div>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {user && !deactivatedAt && (
                            <div>
                              <Checkbox
                                isChecked={user?.disable_self_initiated_payments || false}
                                isDisabled={isDisabled}
                                onChange={(e) => {
                                  return updateDisableSelfInitiatedPayment(
                                    user?.id,
                                    e.target.checked
                                  )
                                }}
                              >
                                {/* Despite the confusing naming, this actually disables all payments */}
                                Disable payments
                              </Checkbox>
                            </div>
                          )}
                        </MenuItem>
                        <MenuItem></MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </HStack>
              </ListItem>
            )
          })}
          {occupants.map((occupant) => {
            const { occupant_number, occupant_name, occupant_relation } = occupant
            return (
              <ListItem style={{ marginBottom: '.5rem' }}>
                <HStack justify='space-between'>
                  <VStack>
                    <b>
                      {occupant_name} - {'Occupant #'}
                      {occupant_number} - {occupant_relation}
                    </b>
                  </VStack>
                </HStack>
              </ListItem>
            )
          })}
        </List>
      </div>
    </ContentSectionCard>
  )
}
