"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RENTAL_PROGRAM_LATE_FEES = exports.DEFAULT_LATE_FEES = void 0;
const rentals_1 = require("./rentals");
const llcs_1 = require("./llcs");
exports.DEFAULT_LATE_FEES = [
    { day: 6, amount: 25, threshold: 300 },
    { day: 11, amount: 50, threshold: 300 }
];
// TODO: Move this to the llcs table to allow more flexibility
exports.RENTAL_PROGRAM_LATE_FEES = {
    [rentals_1.RENTAL_PROGRAM_TYPES.UPANDUP]: {
        default: exports.DEFAULT_LATE_FEES,
        [llcs_1.LLC_NAMES.HREP_IV_SANDALWOOD]: [{ day: 4, percentage: 0.1, threshold: 300 }]
    },
    [rentals_1.RENTAL_PROGRAM_TYPES.VANILLA]: {
        default: exports.DEFAULT_LATE_FEES,
        [llcs_1.LLC_NAMES.HREP_IV_SANDALWOOD]: [{ day: 4, percentage: 0.1, threshold: 300 }]
    }
};
