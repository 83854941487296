import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Text, Flex } from '@chakra-ui/react'

import { AgreementSigners } from 'types/FullRental'
import { formatPersonalDetails } from 'components/CustomerProfile/formats'
import { getContactInfo } from 'lib/users'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { combineFirstNameLastName } from 'lib/utils'
import { RentalForRenewalQuery } from 'graphql/generated'

const RenewRentalModalDetails: React.FC<
  React.PropsWithChildren<{
    rental: NonNullable<Required<RentalForRenewalQuery>['rentals_by_pk']>
    address: string
    rent: number
    optionPremium: number
    agreementSigners: AgreementSigners
    addOns: {
      price: number
      liability_type_name: string
    }[]
  }>
> = ({ rental, address, rent, optionPremium, agreementSigners, addOns }) => {
  return (
    <Box my={'2'}>
      <Text
        fontSize={'md'}
        color={'gray.400'}
        borderBottom={'1px'}
        borderColor={'gray.300'}
        mb={'1'}
      >
        Current Rental Details
      </Text>
      <Text fontSize={'md'} color={'gray.900'}>
        {address}
      </Text>
      <Text fontSize={'md'} color={'gray.900'}>
        ${rent} + ${optionPremium} investment
      </Text>
      {agreementSigners.map(({ user }) => {
        if (!user) {
          return null
        }
        const userRole = rental.rental_users.filter(
          (rentalUser) => rentalUser.user_id === user?.id
        )[0]?.role
        return (
          <div key={user.id}>
            <Flex gap={1}>
              <Link to={{ pathname: `/users/${user?.id}` }} target='_blank'>
                {combineFirstNameLastName(user)}
              </Link>
              {mapContentToBadge(userRole)}
            </Flex>
            <Text fontSize={'md'} color={'gray.400'} pl={'2'}>
              {formatPersonalDetails(getContactInfo('phone', user?.user_contact_details))}
              {'  |  '}
              {getContactInfo('email', user?.user_contact_details)}
            </Text>
          </div>
        )
      })}
      {addOns.length > 0 && (
        <Box>
          <Text fontSize={'md'} color={'gray.900'}>
            Add-Ons:
          </Text>
          <Box pl={4}>
            {[...addOns]
              .sort((a, b) => a.liability_type_name.localeCompare(b.liability_type_name))
              .map((addOn) => (
                <Text fontSize={'md'} color={'gray.400'} key={addOn.liability_type_name}>
                  • {addOn.liability_type_name} (${addOn.price}/month)
                </Text>
              ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default RenewRentalModalDetails
