"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CASHOUT_STATUSES = void 0;
// normally we should source these values from the database enum, e.g. via Prisma
// however the frontend sometimes needs access to the statuses in a dropdown
exports.CASHOUT_STATUSES = {
    PENDING: 'pending',
    MAINTENANCE_CHARGEBACKS_FINALIZED: 'maintenance_chargebacks_finalized',
    UTILITIES_FINALIZED: 'utilities_finalized',
    ADJUSTMENTS_FINALIZED: 'adjustments_finalized',
    CASHED_OUT: 'cashed_out'
};
