import React, { useState } from 'react'

import { Button, TableContainer, Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import AddOnRow from './AddOnRow'
import CreateRentalAddOnModal from './CreateRentalAddOnModal'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { AddOnsFragment } from 'graphql/generated'

export default function AddOns({ rental }: { rental: AddOnsFragment }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const addOns = rental.rental_add_ons || []

  return (
    <ContentSectionCard
      title={'Add-Ons'}
      collapsable={false}
      action={
        <Button size='xs' colorScheme={'teal'} onClick={() => setIsModalOpen(true)}>
          + Add-On
        </Button>
      }
    >
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Add-On</Th>
              <Th>Price</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {addOns.map((addOn) => (
              <AddOnRow addOn={addOn} key={addOn.id} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <CreateRentalAddOnModal
        rentalId={rental.id}
        occupancyDate={rental.occupancy_date}
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </ContentSectionCard>
  )
}
