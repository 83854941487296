import React from 'react'
import startCase from 'lodash/startCase'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  Divider,
  Button,
  Spacer,
  HStack,
  useToast,
  Select,
  Text
} from '@chakra-ui/react'
import { RENEWAL_CANCELATION_REASONS } from '@homevest/utils/renewals'
import axios from 'lib/axios'
interface CancelPendingRenewalModalProps {
  pendingRenewalId: string
  isOpen: boolean
  close: () => void
}

const CancelPendingRenewalModal: React.FC<
  React.PropsWithChildren<CancelPendingRenewalModalProps>
> = ({ pendingRenewalId, isOpen, close }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [cancelationReason, setCancelationReason] = React.useState('')
  const toast = useToast()

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await axios.post(`/admin/renewals/${pendingRenewalId}/cancel`, {
        cancelation_reason: cancelationReason
      })
    } catch (err: any) {
      toast({
        title: 'Error canceling pending renewal!',
        description: `There was an error canceling this renewal: ${err.message}.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
      setIsLoading(false)
      return
    }

    setIsLoading(false)
    toast({
      title: 'Pending renewal canceled!',
      description: 'The pending renewal for this rental has been successfully canceled.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right'
    })

    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Pending Renewal</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <Text>
              Are you sure you want to cancel this renewal? If so, please enter a cancelation reason
              below:
            </Text>
            <Select
              value={cancelationReason}
              onChange={(e) => setCancelationReason(e.target.value)}
            >
              <option value={''}>Select a cancelation reason</option>
              {Object.values(RENEWAL_CANCELATION_REASONS).map((reason) => (
                <option value={reason}>{startCase(reason)}</option>
              ))}
            </Select>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button colorScheme='red' variant='outline' onClick={close}>
              Go Back
            </Button>
            <Spacer />
            <Button
              colorScheme='red'
              isLoading={isLoading}
              onClick={handleSubmit}
              isDisabled={!cancelationReason}
            >
              Cancel Pending Renewal
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CancelPendingRenewalModal
