import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Spinner,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import { dates } from '@homevest/utils'
import { TrashIcon } from 'components/TailwindUIToolkit/StyledComponents'
import {
  Note_Priorities_Enum,
  useDeleteCreditMutation,
  useDeleteLiabilityMutation,
  useUpupInsertNoteMutation
} from 'graphql/generated'
import { LedgerItem } from 'lib/ledger'
import React, { FC, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'

interface DeleteButtonProps {
  ledgerItem: Pick<LedgerItem, 'id' | 'type' | 'amount' | 'date' | 'liabilityLabel'>
  rentalId: string
}
export const DeleteButton: FC<React.PropsWithChildren<DeleteButtonProps>> = ({
  ledgerItem,
  rentalId
}) => {
  const { type, id, amount, date, liabilityLabel } = ledgerItem
  const [isDeleting, setIsDeleting] = useState(false)
  const [_l, deleteLiability] = useDeleteLiabilityMutation()
  const [_c, deleteCredit] = useDeleteCreditMutation()
  const [_n, insertNote] = useUpupInsertNoteMutation()
  const [deleteReason, setDeleteReason] = useState<string>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const admin = useSelector((state: StoreState) => state.admin)
  // function to create a note object with information about the liability or credit being deleted

  const handleDeletion = useCallback(async () => {
    setIsDeleting(true)
    if (type === 'Charge') {
      // delete liability
      await deleteLiability({
        liabilityId: id
      })
    } else if (type === 'Credit') {
      // delete credit
      await deleteCredit({
        liabilityId: id
      })
    }

    let noteText = `Deleted ${dates.formatISODate(
      date
    )} ${liabilityLabel} ${type} (${id}) for $${amount}`
    if (deleteReason) {
      noteText += `
      Reason: ${deleteReason}`
    }
    await insertNote({
      note: noteText,
      category: 'ledger_update',
      created_by_admin_id: admin.id,
      resource_id: rentalId,
      resource_type: 'rentals',
      priority: Note_Priorities_Enum.Default
    })
    setIsDeleting(false)
    onClose()
  }, [
    type,
    insertNote,
    date,
    id,
    amount,
    admin.id,
    rentalId,
    deleteLiability,
    deleteCredit,
    onClose,
    deleteReason
  ])

  if (type === 'Payment') {
    return null
  }
  return (
    <>
      <IconButton
        w='8'
        h='4'
        variant='ghost'
        disabled={isDeleting}
        aria-label={`Delete ${type}`}
        icon={isDeleting ? <Spinner /> : <TrashIcon />}
        onClick={onOpen}
      />
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete {type}
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this {type}? This cannot be reversed without bothering
              Engineering.
              <br />
              <FormControl isRequired isInvalid={!deleteReason} isDisabled={isDeleting}>
                <FormLabel>Reason for Deletion</FormLabel>
                <Textarea
                  value={deleteReason}
                  onChange={(evt) => setDeleteReason(evt.target.value)}
                />
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button isDisabled={isDeleting} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isDisabled={isDeleting || !deleteReason}
                colorScheme='red'
                ml={3}
                onClick={handleDeletion}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
