import React, { FC, useContext, useState } from 'react'
import { format, parse } from 'date-fns'
import startCase from 'lodash/startCase'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import {
  Badge,
  Text,
  Flex,
  HStack,
  Button,
  Input,
  IconButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'
import { CheckIcon, PencilSquareIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { capabilities, dispositions, rentals } from '@homevest/utils'

import Evictions from './Evictions'
import Notes from 'components/Notes'
import Ledger from './Ledger'
import Tenants from './Tenants'
import Documents from './Documents'
import Cashouts from './Cashouts'
import RentalAgreementHistories from './RentalAgreementHistories'
import MoveInPayment from './MoveInPayment'
import MoveInFees from './MoveInFees'
import LineItemsOwed from './LineItemsOwed'
import AddOns from './AddOns'
import BankAccount from './BankAccount'
import Renewals from './Renewals'
import PendingAgreements from './PendingAgreements'
import IssuePOAAmendmentView from './IssuePOAAmendmentView'
import MoveOut from './MoveOut'
import UtilityResponsibilities from './UtilityResponsibilities'
import Buildium from './Buildium'
import RecurringCredits from './RecurringCredits'
import { RentalDetailsContext, RentalDetailsContextProvider } from './RentalDetailsContext'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { getLlc, getRentForRental, getProgramLeaseTypeForRental } from 'lib/rentals'
import { convertDateToUTC } from 'lib/date-time'
import { hasCapability } from 'lib/admin-perms'
import { StoreState } from 'store'
import { useUpdateTargetMonthlyOptionPremiumMutation } from 'graphql/generated'

import NoteWidgetOption from 'types/NoteWidgetOption'
import { numbers } from '@homevest/utils'
import { Loading } from 'ui'

const { LEDGER_VIEWER } = capabilities.CAPABILITY_TYPES
const { DISPOSITION_STATUSES, DISPOSITION_SALE_TYPES } = dispositions
const { RENTAL_PROGRAM_LEASE_TYPES } = rentals

const RentAndOptionPremiumDisplay: FC<
  React.PropsWithChildren<{
    rentalId: string
    rent: any
    optionPremium: number
    rentalProgramType?: string
  }>
> = ({ rentalId, rent, optionPremium, rentalProgramType }) => {
  const [isEditingOptionPremium, setIsEditingOptionPremium] = useState(false)
  const [optionPremiumInput, setOptionPremiumInput] = useState(optionPremium)
  const [_, updateOptionPremium] = useUpdateTargetMonthlyOptionPremiumMutation()

  const rentLabel = `${numbers.formatMoney(rent, 0, '$')} rent`
  const optionPremiumLabel = `${numbers.formatMoney(optionPremium, 0, '$')} investment`

  const EditButton = (
    <>
      <Text fontSize='2xl'>{`${rentLabel} | ${optionPremiumLabel}`}</Text>
      <Button
        pl={1}
        size='sm'
        variant='unstyled'
        disabled={!!rentalProgramType && rentalProgramType !== RENTAL_PROGRAM_LEASE_TYPES.upandup}
        onClick={() => setIsEditingOptionPremium((x) => !x)}
      >
        <PencilSquareIcon height='1.5em' />
      </Button>
    </>
  )

  const EditOptionPremium = (
    <>
      <Text fontSize='2xl'>{`${rentLabel} | $`}</Text>
      <Input
        w='20'
        type='number'
        value={optionPremiumInput}
        onChange={(e) => setOptionPremiumInput(Number(e.target.value))}
      />
      <Text fontSize='2xl'>investment</Text>
      <IconButton
        aria-label='Set'
        icon={<CheckIcon height={24} width={24} />}
        size='sm'
        colorScheme='teal'
        variant='outline'
        onClick={async () => {
          await updateOptionPremium({
            id: rentalId,
            target_monthly_option_premium: optionPremiumInput * 100
          })
          setIsEditingOptionPremium((x) => !x)
        }}
      ></IconButton>
    </>
  )

  return <HStack>{isEditingOptionPremium ? EditOptionPremium : EditButton}</HStack>
}

const TabContent = ({ children }: { children: React.ReactNode }) => (
  <div className='flex flex-col gap-4'>{children}</div>
)

function RentalView({ id }: { id: string }) {
  const { rentalData: rental } = useContext(RentalDetailsContext)
  const history = useHistory()

  const admin = useSelector<StoreState, any>((state) => state.admin)

  const canViewPage = hasCapability(admin, LEDGER_VIEWER)

  if (!canViewPage) {
    history.push('/review')
    return null
  }

  if (!rental) {
    return (
      <div>
        <Button
          leftIcon={<ChevronLeftIcon height={20} />}
          variant='ghost'
          size='md'
          onClick={() => history.push('/rent-roll')}
        >
          Back to Rent Roll
        </Button>
      </div>
    )
  }

  const addressLineOne = rental.portfolio_home?.home?.address?.display_line_1
  const addressLineTwo = rental.portfolio_home?.home?.address?.display_line_2

  const llc = getLlc(rental)

  const rentalProgramType = getProgramLeaseTypeForRental(rental)
  const { optionPremium, rent } = getRentForRental(rental)

  const leaseEndReason = rental.lease_end_reason
  const occupancyDate = rental.occupancy_date
  const portfolioHomeDisposition = rental.portfolio_home?.portfolio_home_dispositions.at(0)
  const isNegotiatingCustomerSale =
    portfolioHomeDisposition &&
    portfolioHomeDisposition.sale_type &&
    portfolioHomeDisposition.sale_type !== DISPOSITION_SALE_TYPES.SALE_TO_MARKET &&
    portfolioHomeDisposition.status !== DISPOSITION_STATUSES.SOLD

  const resourceNoteWidgetOptions: NoteWidgetOption[] = []

  const rentalOption: NoteWidgetOption = {
    id: rental.id,
    type: 'rentals',
    writable: true,
    displayName: `Rental for ${addressLineOne}`
  }
  resourceNoteWidgetOptions.push(rentalOption)

  const collectionsOptions: NoteWidgetOption[] = rental.collections.map((c) => ({
    id: c.id,
    type: 'collections',
    writable: false,
    displayName: `Collection for ${format(parse(c.month, 'yyyy-MM-dd', new Date()), 'MMM yyyy')}`
  }))
  resourceNoteWidgetOptions.push(...collectionsOptions)

  return (
    <div>
      <Helmet>
        <title>Rental | {addressLineOne}</title>
      </Helmet>

      <div className='mt-8'>
        <Flex direction='row' justify='space-between' align='flex-start' mb={6}>
          <Flex direction='column'>
            <Text fontSize={'3xl'}>
              <a href={'/home/' + rental.property.id} target='_blank'>
                {addressLineOne}
                <br />
              </a>
              {addressLineTwo}
            </Text>
            <Text fontSize='2xl'>{llc?.name || 'Need to backfill llc_property'}</Text>
            <RentAndOptionPremiumDisplay
              rentalId={rental.id}
              rent={rent}
              optionPremium={optionPremium}
              rentalProgramType={rentalProgramType}
            />
            <Flex direction='column' gap={1} my={3}>
              <HStack>
                {leaseEndReason && (
                  <Badge colorScheme='red' fontSize='xl' variant='outline'>
                    {startCase(leaseEndReason)}
                  </Badge>
                )}
                {isNegotiatingCustomerSale && (
                  <Badge colorScheme='green' fontSize='xl' variant='outline'>
                    Negotiating Customer Purchase
                  </Badge>
                )}
                {rentalProgramType &&
                  mapContentToBadge(rentalProgramType, {
                    dot: true,
                    size: 'lg'
                  })}
              </HStack>
              <Evictions rental={rental} />
              <Text fontSize='xl'>Occupancy date: {convertAndDisplayDate(occupancyDate)}</Text>
            </Flex>
          </Flex>
          <Flex direction='column' align='flex-end'>
            <Button
              leftIcon={<ChevronLeftIcon height={20} />}
              variant='ghost'
              size='md'
              onClick={() => history.push('/rent-roll')}
            >
              Back to Rent Roll
            </Button>
            <Flex direction='column' mt={2} align='flex-end'>
              {rental.rental_users
                .sort((a, b) => (a.role === 'primary' ? -1 : b.role === 'primary' ? 1 : 0))
                .map((rentalUser) => {
                  const { user, role, deactivated_at: deactivatedAt } = rentalUser
                  if (deactivatedAt) return null
                  return (
                    <Text key={user?.id} fontSize='sm'>
                      {user?.first_name} {user?.last_name}
                      {role === 'primary' && ` (${startCase(role)})`}
                    </Text>
                  )
                })}
            </Flex>
          </Flex>
        </Flex>

        <div className='flex w-full flex-col gap-4 2xl:flex-row'>
          <div className='2xl:w-3/8 w-full'>
            <Tabs colorScheme='teal' isLazy>
              <TabList>
                <Tab>Lease Details</Tab>
                <Tab>Finances</Tab>
                <Tab>Notes</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <TabContent>
                    <IssuePOAAmendmentView />
                    <Tenants rental={rental} />
                    <PendingAgreements rental={rental} />
                    <RentalAgreementHistories rental={rental} />
                    <Documents rental={rental} />
                    {!rental.cashout?.id && <Renewals rental={rental} />}
                    <MoveOut rental={rental} />
                  </TabContent>
                </TabPanel>

                <TabPanel>
                  <TabContent>
                    {rental.cashout?.id && <Cashouts rental={rental} />}
                    <Buildium buildiumLeaseId={rental.buildium_lease_id} />
                    <BankAccount rental={rental} />
                    {!rental?.buildium_lease_id && <MoveInPayment rental={rental} />}
                    {!rental.cashout?.id && <MoveInFees rental={rental} />}
                    {!rental.cashout?.id && <RecurringCredits rental={rental} />}
                    {!rental?.buildium_lease_id && <LineItemsOwed rental={rental} />}
                    <UtilityResponsibilities rental={rental} />
                    {!rental.cashout?.id && <AddOns rental={rental} />}
                  </TabContent>
                </TabPanel>

                <TabPanel>
                  <TabContent>
                    <Notes resourceNoteWidgetOptions={resourceNoteWidgetOptions} />
                  </TabContent>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>

          <div className='2xl:w-5/8 w-full'>
            <Tabs colorScheme='teal' isLazy>
              <TabList>
                <Tab>Ledger</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Ledger rentalId={id} ledgerData={rental} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

function RentalDetails() {
  const { rentalId } = useParams<{ rentalId: string }>()
  return (
    <div style={{ margin: '0 1rem' }}>
      <React.Suspense fallback={<Loading />}>
        <RentalDetailsContextProvider rentalId={rentalId}>
          <RentalView id={rentalId} />
        </RentalDetailsContextProvider>
      </React.Suspense>
    </div>
  )
}

function convertAndDisplayDate(dateObject: any): string {
  return format(convertDateToUTC(dateObject), 'MMMM dd, yyyy')
}

export default RentalDetails
