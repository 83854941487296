import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import IssueCreditForm from './IssueCreditForm'
import IssueRecurringCreditForm from './IssueRecurringCreditForm'
const IssueCreditModal: React.FC<
  React.PropsWithChildren<{
    close: () => void
    isOpen: boolean
    rentalId: string
  }>
> = ({ close, isOpen, rentalId }) => {
  return (
    <>
      <Modal toggle={close} isOpen={isOpen}>
        <ModalBody>
          <Tabs colorScheme='teal' isFitted>
            <TabList mb='1em'>
              <Tab>Issue Credit</Tab>
              <Tab>Issue Recurring Credit</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <IssueCreditForm onSubmit={close} rentalId={rentalId} />
              </TabPanel>
              <TabPanel>
                <IssueRecurringCreditForm onSubmit={close} rentalId={rentalId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </Modal>
    </>
  )
}

IssueCreditModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rentalId: PropTypes.string.isRequired
}

export default IssueCreditModal
