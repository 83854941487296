import React, { useContext } from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import { renewals } from '@homevest/utils'

import { RentalDetailsContext } from '../RentalDetailsContext'
import RenewRentalModal from './CustomRenewalModal/RenewRentalModal'
import DoNotRenewModal from './DoNotRenewModal'
import CancelPendingRenewalModal from './CancelPendingRenewalModal'

const { PENDING_RENEWAL_STATUSES } = renewals

const RenewalActions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isRenewalModalOpen, setIsRenewalModalOpen] = React.useState(false)
  const [isCancelRenewalModalOpen, setIsCancelRenewalModalOpen] = React.useState(false)
  const [isDoNotRenewModalOpen, setIsDoNotRenewModalOpen] = React.useState(false)

  let { rentalData } = useContext(RentalDetailsContext)

  const rentalId = rentalData?.id
  const isDoNotRenewSet = !!rentalData?.do_not_renew_selected_at
  const isDoNotRenewNoticeSent = !!rentalData?.do_not_renew_notice_sent_at

  const renewals = (rentalData?.rahWithRenewal ?? []).map((rah) => rah.renewals).flat()
  const pendingRenewal = renewals.filter((renewal) =>
    (PENDING_RENEWAL_STATUSES as string[]).includes(renewal.status)
  )?.[0]

  if (isDoNotRenewSet || isDoNotRenewNoticeSent) {
    return null
  }

  if (pendingRenewal) {
    return (
      <>
        <Button
          size='xs'
          colorScheme='red'
          variant='solid'
          onClick={() => setIsCancelRenewalModalOpen(true)}
        >
          Cancel Pending Renewal
        </Button>
        <CancelPendingRenewalModal
          pendingRenewalId={pendingRenewal.id}
          isOpen={isCancelRenewalModalOpen}
          close={() => setIsCancelRenewalModalOpen(false)}
        />
      </>
    )
  }

  return (
    <Box>
      <HStack spacing={4}>
        <RenewRentalModal
          rentalId={rentalId}
          isOpen={isRenewalModalOpen}
          setOpen={setIsRenewalModalOpen}
        />
        <Button
          size='xs'
          colorScheme='yellow'
          variant='solid'
          onClick={() => setIsRenewalModalOpen(true)}
        >
          Issue Custom Renewal
        </Button>
        <DoNotRenewModal
          isOpen={isDoNotRenewModalOpen}
          close={() => setIsDoNotRenewModalOpen(false)}
        />
        <Button
          size='xs'
          colorScheme='yellow'
          variant='outline'
          onClick={() => setIsDoNotRenewModalOpen(true)}
        >
          Do Not Renew
        </Button>
      </HStack>
    </Box>
  )
}

export default RenewalActions
