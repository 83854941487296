import React, { useState } from 'react'
import { useOverrideUserIncomeAmountMutation } from 'graphql/generated'
import { UserIncomeDetails } from '@homevest/types/user-incomes'
import {
  Alert,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  MenuButton,
  Menu,
  ButtonProps,
  MenuList
} from '@chakra-ui/react'
import { computeUserIncomeStatus } from 'lib/user-incomes/status'
import axios from 'lib/axios'
import { DotsMenuButton } from 'components/TailwindUIToolkit/Display/DotsMenuButton'

const IncomeActions = ({
  userIncome,
  onAction
}: {
  userIncome: UserIncomeDetails
  onAction?: () => void
}) => {
  const hasOutstandingAdditionalIncomeSourceRequest =
    userIncome.additional_income_requested_at &&
    (!userIncome.additional_income_submitted_at ||
      userIncome.additional_income_requested_at > userIncome.additional_income_submitted_at)

  const userIncomeStatus = computeUserIncomeStatus(userIncome)

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const buttonsAreDisabled = isLoading

  const action =
    (
      action:
        | 'approve'
        | 'non_income_tenant'
        | 'deactivate'
        | 'request_additional_income_source'
        | 'undo_approve'
    ) =>
    async () => {
      setIsLoading(true)
      setErrorMessage(null)

      try {
        await axios.put(`/admin/user_incomes/${userIncome.id}/${action}`)
        onAction?.()
      } catch (error: any) {
        if ('message' in error) {
          setErrorMessage(error.message)
        } else {
          console.error(error)
        }
      } finally {
        setIsLoading(false)
      }
    }

  return (
    <DotsMenuButton>
      <Stack>
        {errorMessage && (
          <Alert status='error' color='red.500'>
            {errorMessage}
          </Alert>
        )}
        {!!userIncome.approved_at ? (
          <ConfirmActionButton
            colorScheme='teal'
            variant='outline'
            action={action('undo_approve')}
            isDisabled={buttonsAreDisabled}
            isLoading={isLoading}
          >
            Undo Approve
          </ConfirmActionButton>
        ) : (
          <ConfirmActionButton
            colorScheme='whatsapp'
            variant={userIncomeStatus === 'approved' ? 'solid' : 'outline'}
            action={action('approve')}
            isDisabled={userIncomeStatus === 'approved' || buttonsAreDisabled}
            isLoading={isLoading}
          >
            Approve
          </ConfirmActionButton>
        )}
        <ConfirmActionButton
          colorScheme='orange'
          variant={hasOutstandingAdditionalIncomeSourceRequest ? 'solid' : 'outline'}
          action={action('request_additional_income_source')}
          isDisabled={hasOutstandingAdditionalIncomeSourceRequest || buttonsAreDisabled}
          isLoading={isLoading}
        >
          Request Additional Source
        </ConfirmActionButton>
        <OverrideMenuButton userIncomeId={userIncome.id} onAction={onAction} />
        <ConfirmActionButton
          colorScheme='blue'
          variant='outline'
          action={action('non_income_tenant')}
          isDisabled={buttonsAreDisabled}
          isLoading={isLoading}
        >
          {userIncome.is_non_income_applicant ? 'Remove Non-Income' : 'Set as Non-Income'}
        </ConfirmActionButton>
        <ConfirmActionButton
          colorScheme='blackAlpha'
          variant='outline'
          action={action('deactivate')}
          isDisabled={buttonsAreDisabled}
          isLoading={isLoading}
        >
          Deactivate
        </ConfirmActionButton>
      </Stack>
    </DotsMenuButton>
  )
}

const ConfirmActionButton = ({
  action,
  isDisabled,
  colorScheme,
  variant,
  children,
  isLoading
}: {
  action: () => void
  colorScheme: ButtonProps['colorScheme']
  variant: ButtonProps['variant']
  children: React.ReactNode
  isDisabled?: boolean
  isLoading?: boolean
}) => {
  return (
    <Menu matchWidth colorScheme={colorScheme} placement='right'>
      <MenuButton
        as={Button}
        size='sm'
        variant={variant}
        isDisabled={isDisabled}
        colorScheme={colorScheme}
      >
        {children}
      </MenuButton>
      <MenuList w='full' h='fit-content'>
        <Stack px={2}>
          <Button
            size='sm'
            colorScheme={colorScheme}
            w='full'
            onClick={action}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </Stack>
      </MenuList>
    </Menu>
  )
}

const OverrideMenuButton = ({
  userIncomeId,
  onAction
}: {
  userIncomeId: string
  onAction?: () => void
}) => {
  const [{ fetching }, mutate] = useOverrideUserIncomeAmountMutation()

  const [overrideAmount, setOverrideAmount] = useState<number | ''>('')

  return (
    <Menu matchWidth placement='right'>
      <MenuButton
        as={Button}
        size='sm'
        colorScheme='yellow'
        variant='outline'
        isDisabled={fetching}
      >
        Override Amount
      </MenuButton>
      <MenuList p={2}>
        <HStack w='full'>
          <InputGroup size='sm'>
            <InputLeftAddon fontSize='sm'>$</InputLeftAddon>
            <Input
              size='sm'
              type='number'
              value={overrideAmount}
              onChange={(e) => setOverrideAmount(Number(e.target.value))}
            />
          </InputGroup>
          <Button
            colorScheme='yellow'
            size='sm'
            isDisabled={overrideAmount === '' || fetching}
            isLoading={fetching}
            onClick={async () => {
              await mutate({ userIncomeId, amount: overrideAmount })
              onAction?.()
            }}
          >
            Save
          </Button>
        </HStack>
      </MenuList>
    </Menu>
  )
}

export default IncomeActions
