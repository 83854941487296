import React from 'react'
import { Heading } from '@chakra-ui/react'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { EstimatedCostHistoriesGrid } from './components/EstimatedCostHistories/EstimatedCostHistoriesGrid'

const EstimatedCostHistoriesCard: React.FC<{ constructionProjectId: string }> = ({
  constructionProjectId
}) => (
  <ContentSectionCard
    title={
      <Heading as='h4' size='md' my={1}>
        Estimated Cost History
      </Heading>
    }
  >
    <EstimatedCostHistoriesGrid constructionProjectId={constructionProjectId} />
  </ContentSectionCard>
)

export default EstimatedCostHistoriesCard
