import { numbers } from '@homevest/utils'
import { ColDef, ICellRendererParams, RowClassParams, RowStyle } from 'ag-grid-community'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { RentRollQuery } from 'graphql/generated'
import { getRentRollStatus, RentRollStatus } from 'lib/rent-roll/statuses'
import AutoChargeCheckbox from '../AutoChargeCheckbox'
import { differenceInDays, addDays } from 'date-fns'
import { parseDate } from 'lib/date-time'
import { getProgramLeaseTypeForRental } from 'lib/rentals'
import SetFilter from 'components/AgGrid/filters/SetFilter'
import { Colors } from 'components/TailwindUIToolkit'

const { formatMoney } = numbers

export type RentRollRentalType = RentRollQuery['rent_roll'][number]

const ADDRESS: ColDef<RentRollRentalType> = {
  headerName: 'Rental',
  valueGetter: (params) => {
    return `${params.data?.display_line_1}`
  },
  filterValueGetter: (params) => {
    const tenant = params.data?.rental?.rental_users?.[0]?.user
    return `${params.data?.display_line_1} ${params.data?.display_line_2} ${tenant?.first_name} ${tenant?.last_name}`
  }
}

const CITY: ColDef<RentRollRentalType> = {
  headerName: 'City',
  field: 'city'
}

const ZIP: ColDef<RentRollRentalType> = {
  headerName: 'Zip',
  field: 'zip'
}

const FUND: ColDef<RentRollRentalType> = {
  headerName: 'Fund',
  field: 'rental.portfolio_home.most_recent_llc_property.llc.fund_llcs.0.fund.name',
  filter: SetFilter
}
const LLC: ColDef<RentRollRentalType> = {
  headerName: 'LLC',
  field: 'rental.portfolio_home.most_recent_llc_property.llc.name',
  filter: SetFilter
}

const TENANT: ColDef<RentRollRentalType> = {
  headerName: 'Primary Tenant',
  valueGetter: (params) => {
    const tenant = params.data?.rental?.rental_users?.[0]?.user
    return `${tenant?.first_name} ${tenant?.last_name}`
  }
}

const OCCUPANCY_DATE: ColDef<RentRollRentalType> = {
  headerName: 'Occupancy Date',
  field: 'occupancy_date',
  filter: 'date'
}

const LEASE_END_DATE: ColDef<RentRollRentalType> = {
  headerName: 'Lease End Date',
  valueGetter: (params) => {
    return params.data?.move_out_date || params.data?.final_liability_date || params.data?.ends_at
  },
  // https://stackoverflow.com/questions/57484775/ag-grid-custom-tooltip-component-template-not-showing
  tooltipField: 'noop',
  headerTooltip:
    'If a move out date or final liability date is set, this will show that date, otherwise it will use the scheduled end date of the lease.',
  filter: 'date'
}

const MOVE_OUT_DATE: ColDef<RentRollRentalType> = {
  headerName: 'Move Out Date',
  field: 'move_out_date',
  filter: 'date',
  headerTooltip:
    'This is the date entered in Hudson on which the tenant will actually move out or has moved out of the rental'
}

const RENT_ROLL_STATUS: ColDef<RentRollRentalType> = {
  headerName: 'Status',
  valueGetter: (params) => (params.data ? getRentRollStatus(params.data) : 'unknown'),
  cellRenderer: (params: ICellRendererParams<RentRollRentalType>) =>
    mapContentToBadge(params.value, { dot: false }),
  filter: SetFilter
}

const RENTAL_PROGRAM_TYPE: ColDef<RentRollRentalType> = {
  headerName: 'Program Type',
  valueGetter: (params) => getProgramLeaseTypeForRental(params.data?.rental),
  cellRenderer: (params: ICellRendererParams<RentRollRentalType>) =>
    mapContentToBadge(params.value, { dot: false }),
  filter: SetFilter
}

const RENEWAL_TERM = {
  headerName: 'Renewal Term',
  field: 'renewal_months'
}

const RENT: ColDef<RentRollRentalType> = {
  headerName: 'Rent',
  field: 'rent',
  filter: 'number',
  valueFormatter: (params) => formatMoney(params.value, 2, '$')
}

const OPTION_PREMIUM: ColDef<RentRollRentalType> = {
  headerName: 'Option Premium',
  field: 'option_premium',
  valueGetter: (params) => {
    return params.data?.option_premium || (params.data?.target_monthly_option_premium ?? 0) / 100
  },
  filter: 'number',
  valueFormatter: (params) => formatMoney(params.value, 2, '$')
}

const BALANCE: ColDef<RentRollRentalType> = {
  headerName: 'Balance',
  field: 'aggregate_balance_after',
  filter: 'number',
  valueFormatter: (params) => formatMoney(params.value, 2, '$'),
  cellStyle: (params) => {
    if (params.value > 400) {
      return { color: 'red' }
    }
    return null
  }
}

const AUTO_CHARGE: ColDef<RentRollRentalType> = {
  headerName: 'Auto Charge?',
  field: 'auto_charge',
  cellRenderer: (params: ICellRendererParams<RentRollRentalType>) => {
    return params.data && AutoChargeCheckbox({ rental: params.data })
  }
}

const CASHOUT_STATUS: ColDef<RentRollRentalType> = {
  headerName: 'Cashout Status',
  field: 'rental.cashout.status',
  cellRenderer: (params: ICellRendererParams<RentRollRentalType>) => {
    return mapContentToBadge(params.value, { dot: false })
  },
  onCellDoubleClicked: (params) => {
    params.context?.history?.push(`/rent-roll/${params.data?.rental?.id}`)
  },
  filter: SetFilter
}

const WALLET_VALUE: ColDef<RentRollRentalType> = {
  headerName: 'Wallet Value',
  field: 'rental.statements.0.ending_balance',
  valueFormatter: (params) => formatMoney(params.value, 2, '$')
}

const DAYS_SINCE_MOVE_OUT: ColDef<RentRollRentalType> = {
  headerName: 'Days Since Move Out',
  valueGetter: (params) => {
    const moveOutDate = params.data?.move_out_date
    if (!moveOutDate) {
      return null
    }
    return differenceInDays(new Date(), parseDate(moveOutDate))
  }
}

const CHARGEBACK_DEADLINE: ColDef<RentRollRentalType> = {
  headerName: 'Chargeback Deadline',
  field: 'chargeback_deadline',
  valueGetter: (params) => {
    const moveOutDate = params.data?.move_out_date
    const chargebackDeadline = params.data?.chargeback_deadline // Access the cashout_deadline directly
    if (!moveOutDate || !chargebackDeadline) {
      return null
    }
    const deadlineDate = addDays(parseDate(moveOutDate), chargebackDeadline)
    return differenceInDays(deadlineDate, new Date())
  },
  cellRenderer: (params: ICellRendererParams<RentRollRentalType>) => {
    return getDaysLeftBadge(params.value)
  }
}

const CASHOUT_DEADLINE: ColDef<RentRollRentalType> = {
  headerName: 'Cashout Deadline',
  field: 'cashout_deadline',
  valueGetter: (params) => {
    const moveOutDate = params.data?.move_out_date
    const cashoutDeadline = params.data?.cashout_deadline
    if (!moveOutDate || !cashoutDeadline) {
      return null
    }
    const deadlineDate = addDays(parseDate(moveOutDate), cashoutDeadline)
    return differenceInDays(deadlineDate, new Date())
  },
  cellRenderer: (params: ICellRendererParams<RentRollRentalType>) => {
    return getDaysLeftBadge(params.value)
  }
}

const DATES = [OCCUPANCY_DATE, LEASE_END_DATE, MOVE_OUT_DATE]

const DEFAULT_COLUMNS: ColDef<RentRollRentalType>[] = [
  ADDRESS,
  CITY,
  ZIP,
  TENANT,
  ...DATES,
  RENT_ROLL_STATUS,
  RENTAL_PROGRAM_TYPE,
  RENEWAL_TERM,
  RENT,
  OPTION_PREMIUM,
  BALANCE,
  AUTO_CHARGE,
  FUND,
  LLC
]

const CASHED_OUT_COLUMNS: ColDef<RentRollRentalType>[] = [
  ADDRESS,
  FUND,
  LLC,
  TENANT,
  RENTAL_PROGRAM_TYPE,
  DAYS_SINCE_MOVE_OUT,
  CASHOUT_STATUS,
  WALLET_VALUE,
  BALANCE,
  MOVE_OUT_DATE,
  CHARGEBACK_DEADLINE,
  CASHOUT_DEADLINE
]

const getDaysLeftBadge = (daysLeft: number | null) => {
  if (daysLeft === null) {
    return ''
  } else if (daysLeft < 0) {
    return mapContentToBadge(`${daysLeft} days past`, { colorOverride: 'Red' })
  } else if (daysLeft === 0) {
    return mapContentToBadge(`Due today`, { colorOverride: 'Orange' })
  } else if (daysLeft === 1) {
    return mapContentToBadge(`${daysLeft} day left`, { colorOverride: 'Yellow' })
  } else if (daysLeft <= 3) {
    return mapContentToBadge(`${daysLeft} days left`, { colorOverride: 'Yellow' })
  }
  return mapContentToBadge(`${daysLeft} days left`)
}

export const getColumnsForStatus = (status?: RentRollStatus) => {
  if (!status) {
    return DEFAULT_COLUMNS
  }

  switch (status) {
    case 'ready_to_be_cashed_out':
      return CASHED_OUT_COLUMNS
    default:
      return DEFAULT_COLUMNS
  }
}

export const getRowStyleForStatus = (
  params: RowClassParams<RentRollRentalType>,
  status?: RentRollStatus
): RowStyle | undefined => {
  if (!status) {
    return undefined
  }

  switch (status) {
    case 'ready_to_be_cashed_out':
      if (params.node?.data?.move_out_date) {
        const numDays = differenceInDays(new Date(), parseDate(params.node?.data?.move_out_date))
        if (numDays < 60) {
          return undefined
        }
        if (numDays < 70) {
          return {
            background: Colors.YELLOW_100
          }
        }
        if (numDays < 75) {
          return {
            background: Colors.ORANGE_100
          }
        }

        return { background: Colors.RED_200 }
      }
      return undefined

    default:
      return undefined
  }
}
