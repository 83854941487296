import React from 'react'
import {
  EstimatedCostHistoryApiResponse,
  EstimatedCostHistoriesApiResponse
} from '@homevest/types/construction-projects'
import { Spinner, Center } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { ColDef } from 'ag-grid-community'

import axios from 'lib/axios'
import { BaseGrid } from 'components/TailwindUIToolkit'
import { formatUTCDate } from 'lib/date-time'

export const EstimatedCostHistoriesGrid: React.FC<{
  constructionProjectId: string
}> = ({ constructionProjectId }) => {
  // get estimated cost histories data
  const { data, isFetching, isLoading } = useQuery({
    queryKey: ['getEstimatedCostHistories', constructionProjectId],
    queryFn: async () => {
      const response = await axios.get<EstimatedCostHistoriesApiResponse>(
        `/admin/construction_projects/${constructionProjectId}/estimated_cost_histories`
      )
      return response.data?.data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  return (
    <div className='h-[60vh]'>
      {isFetching || isLoading || !data ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <BaseGrid
          rowData={data ?? []}
          columns={[...estimatedCostHistoriesColumns]}
          defaultColDefOverrides={{
            wrapHeaderText: true
          }}
        />
      )}
    </div>
  )
}

export const estimatedCostHistoriesColumns: ColDef<EstimatedCostHistoryApiResponse>[] = [
  {
    colId: 'old_estimated_cost',
    headerName: 'Old Estimated Cost',
    field: 'old_estimated_cost'
  },
  {
    colId: 'new_estimated_cost',
    headerName: 'New Estimated Cost',
    field: 'new_estimated_cost'
  },
  {
    colId: 'entered_estimated_cost_at',
    headerName: 'Entered Estimated Cost At',
    field: 'entered_estimated_cost_at',
    valueFormatter: (params) => {
      return params.value ? formatUTCDate(params.value, 'MM/dd/yy') : ''
    }
  },
  {
    colId: 'left_estimated_cost_at',
    headerName: 'Left Estimated Cost At',
    field: 'left_estimated_cost_at',
    valueFormatter: (params) => {
      return params.value ? formatUTCDate(params.value, 'MM/dd/yy') : ''
    }
  },
  {
    colId: 'changed_by',
    headerName: 'Changed By',
    valueGetter: (params) => {
      if (!params.data?.changed_by_admin) {
        return ''
      }
      return `${params.data?.changed_by_admin?.first_name} ${params.data?.changed_by_admin?.last_name}`
    },
    resizable: true
  }
]
