"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RENEWAL_CANCELATION_REASONS = exports.NON_PENDING_RENEWAL_STATUSES = exports.PENDING_RENEWAL_STATUSES = exports.RENEWAL_STATUSES = void 0;
exports.RENEWAL_STATUSES = {
    PENDING_OFFER: 'pending_offer',
    OFFERED: 'offered',
    RENEWAL_OPTION_SELECTED: 'renewal_option_selected',
    PENDING_CUSTOMER_SIGNATURE: 'pending_customer_signature',
    PENDING_UPANDUP_SIGNATURE: 'pending_upandup_signature',
    CANCELED: 'canceled',
    COMPLETE: 'complete'
};
exports.PENDING_RENEWAL_STATUSES = [
    exports.RENEWAL_STATUSES.PENDING_OFFER,
    exports.RENEWAL_STATUSES.OFFERED,
    exports.RENEWAL_STATUSES.RENEWAL_OPTION_SELECTED,
    exports.RENEWAL_STATUSES.PENDING_CUSTOMER_SIGNATURE,
    exports.RENEWAL_STATUSES.PENDING_UPANDUP_SIGNATURE
];
exports.NON_PENDING_RENEWAL_STATUSES = [exports.RENEWAL_STATUSES.COMPLETE, exports.RENEWAL_STATUSES.CANCELED];
exports.RENEWAL_CANCELATION_REASONS = {
    CREATING_NEW_OFFER: 'creating_new_offer',
    DOMESTIC_ISSUES: 'domestic_issues',
    FINANCIAL_DIFFICULTIES: 'financial_difficulties',
    NEGATIVE_CUSTOMER_EXPERIENCE: 'negative_customer_experience',
    PURCHASED_OTHER_HOME: 'purchased_other_home',
    RELOCATION: 'relocation',
    OTHER: 'other'
};
