import React from 'react'
import startCase from 'lodash/startCase'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { Link } from '@chakra-ui/react'
import { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'

import { formatMoney } from 'lib/numbers'
import { formatUTCDate, formatDateWithDistance } from 'lib/date-time'
import { formatProjectTitle, projectStatusComparator } from 'lib/construction-projects'
import SetFilter from 'components/AgGrid/filters/SetFilter'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { LINK_COLOR } from 'constants/colors'

const ID: ColDef<ConstructionProjectApiResponse> = {
  colId: 'id',
  headerName: 'ID',
  field: 'id',
  hide: true
}

const PROJECT: ColDef<ConstructionProjectApiResponse> = {
  colId: 'project',
  headerName: 'Project',
  resizable: true,
  valueGetter: (params) =>
    formatProjectTitle(params?.data?.type, params?.data?.address, params?.data?.start_date),
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse>) => {
    const { data, value } = params
    return (
      <Link isExternal color={LINK_COLOR} href={`/construction/projects/${data?.id}`}>
        {value}
      </Link>
    )
  },
  flex: 2
}

const ADDRESS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'address',
  headerName: 'Address',
  field: 'address',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse>) => {
    const { data } = params
    return (
      <Link isExternal color={LINK_COLOR} href={`/home/${data?.portfolio_home_id}`}>
        {data?.address ? `${data.address}, ${data.city}, ${data.state}, ${data?.zip}` : ''}
      </Link>
    )
  },
  valueFormatter: (params) => startCase(params.value),
  flex: 2
}

const MARKET: ColDef<ConstructionProjectApiResponse> = {
  colId: 'market',
  headerName: 'Market',
  field: 'market',
  resizable: true,
  valueFormatter: (params) => startCase(params.value),
  filter: SetFilter,
  flex: 1
}

const ASSIGNED_TO: ColDef<ConstructionProjectApiResponse> = {
  colId: 'assigned_to_admin_id',
  headerName: 'Assigned To',
  valueGetter: (params) => {
    if (!params.data?.assigned_to_admin_id) {
      return ''
    }
    return `${params.data?.assigned_admin?.first_name} ${params.data?.assigned_admin?.last_name}`
  },
  resizable: true,
  flex: 1
}

const GENERAL_CONTRACTOR: ColDef<ConstructionProjectApiResponse> = {
  colId: 'general_contractor_id',
  headerName: 'General Contractor',
  valueGetter: (params) => {
    if (!params.data?.general_contractor_id) {
      return ''
    }
    return `${params.data?.general_contractor?.name}`
  },
  resizable: true,
  filter: SetFilter,
  flex: 1
}

const TYPE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'type',
  headerName: 'Type',
  field: 'type',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  flex: 1
}

const STATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'status',
  headerName: 'Status',
  field: 'status',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  comparator: (valueA, valueB) => projectStatusComparator(valueA, valueB),
  flex: 1
}

const LAST_STATUS_CHANGE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'last_status_change_at',
  headerName: 'Last Status Change',
  field: 'last_status_change_at',
  resizable: true,
  valueFormatter: (params) => {
    return params.value ? formatDateWithDistance(params.value, 'MMM do, yyyy') : '-'
  },
  wrapText: true,
  flex: 1
}

const SUBSTATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'substatus',
  headerName: 'Blocked?',
  field: 'substatus',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    // Display "Yes" if blocked, "No" otherwise
    const isBlocked = params.value === 'blocked'
    const value = isBlocked ? 'Yes' : 'No'
    const color = isBlocked ? 'Red' : 'Green'
    return mapContentToBadge(value, { size: 'xs', colorOverride: color })
  },
  filter: SetFilter,
  flex: 1
}

const TRACKING_STATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'tracking_status',
  headerName: 'Escalated',
  field: 'tracking_status',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    // Display "Yes" if escalated, "No" otherwise
    const isEscalated = params.value === 'escalated'
    const value = isEscalated ? 'Yes' : 'No'
    const color = isEscalated ? 'Purple' : 'Green'
    return mapContentToBadge(value, { size: 'xs', colorOverride: color })
  },
  filter: SetFilter,
  flex: 1
}

const START_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'start_date',
  headerName: 'Start Date',
  field: 'start_date',
  resizable: true,
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  flex: 1
}

const END_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'end_date',
  headerName: 'End Date',
  field: 'end_date',
  resizable: true,
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  flex: 1
}

const MOVE_OUT_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'move_out_date',
  headerName: 'Move Out Date',
  field: 'prior_rental.move_out_date',
  resizable: true,
  sort: 'asc',
  sortIndex: 0,
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  flex: 1
}

const NEXT_MOVE_IN_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'next_move_in_date',
  headerName: 'Next Move In Date',
  field: 'next_rental.occupancy_date',
  resizable: true,
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  flex: 1
}

const ESTIMATED_COST: ColDef<ConstructionProjectApiResponse> = {
  colId: 'estimated_cost',
  headerName: 'Est Cost ',
  field: 'estimated_cost',
  resizable: true,
  valueFormatter: (params) => formatMoney(parseFloat(params.value), 2, '$'),
  flex: 1
}

const MOST_RECENT_NOTE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'most_recent_note',
  headerName: 'Most Recent Note',
  field: 'most_recent_note',
  resizable: true,
  cellStyle: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical'
  },
  autoHeight: false,
  valueGetter: (params) => params?.data?.most_recent_note?.note,
  valueFormatter: (params) =>
    params.value
      ? `(${formatUTCDate(params?.data?.most_recent_note?.created_at, 'MM/dd')}) ${params.value}`
      : '',
  comparator: (_valueA, _valueB, nodeA, nodeB) => {
    const createdAtA = nodeA.data?.most_recent_note?.created_at
    const createdAtB = nodeB.data?.most_recent_note?.created_at
    if (!createdAtA) {
      return !!createdAtB ? -1 : 0
    } else if (!createdAtB) {
      return 1
    }

    return createdAtA > createdAtB ? 1 : -1
  },
  tooltipValueGetter: (params) =>
    params.value
      ? `(${formatUTCDate(params?.data?.most_recent_note?.created_at, 'MM/dd')}) ${params.value}`
      : '',
  flex: 2,
  maxWidth: 400
}

export const BASE_COLUMNS = [
  ID,
  PROJECT,
  ADDRESS,
  MARKET,
  ASSIGNED_TO,
  GENERAL_CONTRACTOR,
  TYPE,
  STATUS,
  LAST_STATUS_CHANGE,
  SUBSTATUS,
  TRACKING_STATUS,
  START_DATE,
  END_DATE,
  MOVE_OUT_DATE,
  NEXT_MOVE_IN_DATE,
  ESTIMATED_COST,
  MOST_RECENT_NOTE
]

const DISPOSITION_STATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'disposition_status',
  headerName: 'Disposition Status',
  field: 'disposition.status',
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  resizable: true,
  flex: 1
}

const DISPOSITION_IS_STAGED: ColDef<ConstructionProjectApiResponse> = {
  colId: 'disposition_is_staged',
  headerName: 'Is Staged',
  field: 'disposition.is_staged',
  valueFormatter: (params) => {
    return params.value === true ? 'Yes' : params.value === false ? 'No' : ''
  },
  resizable: true,
  flex: 1
}

const DISPOSITION_CLOSING_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'disposition_closing_date',
  headerName: 'Closing Date',
  field: 'disposition.closing_date',
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  resizable: true,
  flex: 1
}

export const DISPOSITION_COLUMNS = [
  ID,
  PROJECT,
  ADDRESS,
  MARKET,
  ASSIGNED_TO,
  TYPE,
  STATUS,
  LAST_STATUS_CHANGE,
  SUBSTATUS,
  TRACKING_STATUS,
  START_DATE,
  END_DATE,
  MOVE_OUT_DATE,
  DISPOSITION_STATUS,
  DISPOSITION_IS_STAGED,
  DISPOSITION_CLOSING_DATE,
  ESTIMATED_COST,
  MOST_RECENT_NOTE
]

const OCCUPANCY_INSPECTION_STATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'occupancy_inspection_status',
  headerName: 'OI Status',
  field: 'occupancy_inspection.status',
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  resizable: true,
  flex: 1
}

// const IS_INITIAL_OCCUPANCY_INSPECTION: ColDef<ConstructionProjectApiResponse> = {
//   colId: 'is_first_occupancy_inspection',
//   // Need to overwrite default headerValueGetter so question mark doesn't disappear
//   headerName: 'IS INITIAL OI?',
//   headerValueGetter: (params) => params.colDef.headerName,
//   field: 'occupancy_inspection.is_initial_occupancy_inspection',
//   valueFormatter: (params) => {
//     if (params.value === null || params.value === undefined) {
//       return ''
//     }
//     return params.value ? 'Yes' : 'No'
//   },
//   resizable: true,
//   flex: 1
// }

const OCCUPANCY_INSPECTION_NUMBER_OF_INSPECTIONS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'occupancy_inspection_number_of_inspections',
  headerName: 'Inspection Number',
  field: 'occupancy_inspection.number_of_occupancy_inspections',
  valueFormatter: (params) => {
    return params.value ? `#${params.value}` : ''
  },
  resizable: true,
  flex: 1
}

const OCCUPANCY_INSPECTION_SCHEDULED_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'occupancy_inspection_at',
  headerName: 'OI Scheduled At',
  field: 'occupancy_inspection.inspection_at',
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  resizable: true,
  flex: 1
}

export const OCCUPANCY_INSPECTION_COLUMNS = [
  OCCUPANCY_INSPECTION_STATUS,
  OCCUPANCY_INSPECTION_NUMBER_OF_INSPECTIONS,
  OCCUPANCY_INSPECTION_SCHEDULED_DATE
]
