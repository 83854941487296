import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { UncontrolledTooltip } from 'reactstrap'
import startCase from 'lodash/startCase'
import { useHistory, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RentalLedger } from '@homevest/types/ledger'
import { capabilities, ledger as LEDGER, payments as PAYMENTS } from '@homevest/utils'

import {
  Button,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  IconButton
} from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icon'
import {
  ArrowDownOnSquareIcon,
  MagnifyingGlassPlusIcon,
  ReceiptRefundIcon
} from '@heroicons/react/24/outline'
import { formatMoney } from 'lib/numbers'
import { getLedgerItemsWithBalance } from 'lib/ledger'
import {
  getAddressForRental,
  getRentalDetailDisplayData,
  getCSVExportForRentalLedger
} from 'lib/rentals'
import { hasCapability } from 'lib/admin-perms'
import CreateRentalLiabilityModal from './CreateRentalLiabilityModal'
import IssueCreditModal from './IssueCreditModal'
import IssuePaymentRefundModal from './IssuePaymentRefundModal'
import BackfillModal from './BackfillModal'
import LedgerDetailModal from './LedgerDetailModal'
import { StoreState } from 'store'
import { RefundInfoType } from '../types'
import { DeleteButton } from './LedgerRow/DeleteButton'
import { EditButton } from './LedgerRow/EditButton'
import moment from 'moment'
import { RentRollRentalSubscription } from 'graphql/generated'
import axios from 'lib/axios'
import { dates } from '@homevest/utils'
import { CSVLink } from 'react-csv'
import CashoutValidAlert from './CashoutValidAlert'

const {
  LEDGER_LIABILITY_CREATOR,
  LEDGER_RENT_CREDIT_ISSUER,
  LEDGER_VIEWER,
  PAYMENT_REFUNDER,
  LEDGER_SUPER_EDITOR
} = capabilities.CAPABILITY_TYPES

const { PAYMENT_LIFECYCLE, PAYMENT_STATUSES } = PAYMENTS

const FAILED_STATUSES: string[] = [PAYMENT_STATUSES.FAILED, PAYMENT_STATUSES.NSF_FAILED]

const fetchLedger = async (rentalId: string) => {
  return (
    await axios.get<RentalLedger>(
      `/admin/rentals/${rentalId}/ledger?filter_date=${dates.formatISODate(new Date())}`
    )
  ).data
}

export default function Ledger({
  rentalId,
  ledgerData
}: {
  ledgerData: NonNullable<RentRollRentalSubscription['rentals_by_pk']>
  rentalId: string
}) {
  const [isCreateRentalLiabilityModalOpen, setIsCreateRentalLiabilityModalOpen] = useState(false)
  const [isLedgerDetailModalOpen, setIsLedgerDetailModalOpen] = useState(false)
  const [ledgerDetailModalData, setLedgerDetailModalData] = useState({})
  const [isIssueCreditModalOpen, setIsIssueCreditModalOpen] = useState(false)
  const [isBackfillModalOpen, setIsBackfillModalOpen] = useState(false)
  const admin = useSelector((state: StoreState) => state.admin)
  const [isRefundPaymentModalOpen, setIsRefundPaymentModalOpen] = useState(false)
  const [refundInfo, setRefundInfo] = useState<RefundInfoType>()
  const [ledger, setLedger] = useState<RentalLedger>()
  const [ledgerDetailModalHeader, setLedgerDetailModalHeader] = useState('')
  const history = useHistory()

  useEffect(() => {
    fetchLedger(rentalId).then((ledger) => {
      setLedger(ledger)
    })
  }, [rentalId])

  const canViewPage = hasCapability(admin, LEDGER_VIEWER)
  const canIssueRentCredits = hasCapability(admin, LEDGER_RENT_CREDIT_ISSUER)
  const canCreateLiabilities = hasCapability(admin, LEDGER_LIABILITY_CREATOR)
  const canRefund = hasCapability(admin, PAYMENT_REFUNDER)
  const canEditLedger = hasCapability(admin, LEDGER_SUPER_EDITOR)
  const canEditEntry = (date: string) => {
    const today = new Date()
    const dateMoment = moment(date)
    return (
      canEditLedger &&
      (dateMoment.isSameOrAfter(today, 'day') ||
        (today.getMonth() === dateMoment.month() && today.getFullYear() === dateMoment.year()))
    )
  }

  const isLedgerLocked = !!ledgerData?.cashout?.adjustments_finalized_at
  const isTenantCashedOut = !!ledgerData?.cashout?.cashed_out_at

  const rentals_by_pk = ledgerData
  if (!canViewPage) {
    history.push('/review')
    return null
  }

  const rental = getRentalDetailDisplayData(rentals_by_pk)
  const { ledgerDataWithBalances } = getLedgerItemsWithBalance(
    rentals_by_pk.payments,
    rentals_by_pk.rental_liabilities,
    rentals_by_pk.rental_credits,
    rentals_by_pk.cashout?.cashout_adjustments
  )

  const address = getAddressForRental(rentals_by_pk)
  const finalStatementDate = ledger?.finalStatement?.date

  const processingTotal = rental.payments
    .filter((payment) => payment.status === 'processing')
    .reduce((total, payment) => total + payment.amount, 0)

  return (
    <>
      <HStack spacing={20} alignItems='start'>
        <TableContainer>
          <Table size='sm'>
            <Tbody>
              <Tr>
                <Td className='font-bold'>Outstanding Balance</Td>
                <Td>
                  <span className='font-bold'>{formatMoney(ledger?.balance, 2, '$')}</span>
                  {processingTotal > 0 && ` (+${formatMoney(processingTotal, 2, '$')} processing)`}
                </Td>
              </Tr>
              {rental.investmentTransfer && (
                <Tr className='font-bold'>
                  <Td>Investment Transfer</Td>
                  <Td>{formatMoney(rental.investmentTransfer, 2, '$')}</Td>
                </Tr>
              )}
              {ledger?.finalStatement && (
                <Tr>
                  <Td>
                    <span className='font-bold'>Wallet Value</span>
                    {finalStatementDate
                      ? ` (as of ${format(new Date(finalStatementDate), 'MMM yyyy')})`
                      : ''}
                  </Td>
                  <Td className='font-bold'>
                    {formatMoney(Number(ledger?.finalStatement?.ending_balance), 2, '$')}
                  </Td>
                </Tr>
              )}
              {isTenantCashedOut && (
                <Tr className='font-bold'>
                  <Td>Cashout Amount</Td>
                  <Td>{formatMoney(rental.cashout?.paid_out_amount, 2, '$')}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <div>
          <HStack spacing={7} w='150%'>
            {!isLedgerLocked && canCreateLiabilities && (
              <Button
                w='100%'
                size='xs'
                bg='teal.300'
                color='white'
                onClick={() => setIsCreateRentalLiabilityModalOpen(true)}
              >
                + Liability
              </Button>
            )}
            {!isLedgerLocked && canIssueRentCredits && (
              <Button
                w='100%'
                size='xs'
                bg='teal.500'
                color='white'
                onClick={() => setIsIssueCreditModalOpen(true)}
              >
                + Credit
              </Button>
            )}
            {!isLedgerLocked && (
              <Button
                w='100%'
                size='xs'
                bg='teal.700'
                color='white'
                onClick={() => setIsBackfillModalOpen(true)}
              >
                Backfill Payment
              </Button>
            )}
            {ledger && (
              <Button w='100%' size='xs' bg='teal.900' color='white'>
                <CSVLink
                  data={getCSVExportForRentalLedger(rentals_by_pk, ledger)}
                  filename={`${address}-ledger-${new Date().getTime()}.csv`}
                >
                  <HStack spacing={1}>
                    <Icon as={ArrowDownOnSquareIcon} />
                    <span>Ledger</span>
                  </HStack>
                </CSVLink>
              </Button>
            )}
          </HStack>
        </div>
      </HStack>
      {rental.cashout && ledger && <CashoutValidAlert ledger={ledger} />}
      <TableContainer mt={2}>
        <Table size='xs' variant='striped'>
          <Thead>
            <Tr>
              <Th fontSize='xs'></Th>
              <Th fontSize='xs'>Charge Date</Th>
              <Th fontSize='xs'>Available on</Th>
              <Th fontSize='xs'>Amount</Th>
              {canRefund && <Th fontSize='xs'>Refund</Th>}
              <Th fontSize='xs'>Type</Th>
              <Th fontSize='xs'>Category</Th>
              <Th fontSize='xs'>Paid by</Th>
              <Th fontSize='xs'>Status</Th>
              <Th fontSize='xs'>Payment Method</Th>
              <Th fontSize='xs'>Balance</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ledgerDataWithBalances.map(
              ({
                id,
                user,
                amount,
                availableOn,
                balance,
                date,
                paymentMethod,
                liabilityLabel,
                type,
                status,
                lifecycle,
                initiatedAt,
                note,
                refundReason,
                liabilityTypeId,
                creditType,
                stripeId,
                buildiumId
              }) => (
                <Tr
                  key={id}
                  className={[
                    FAILED_STATUSES.includes(status) ? 'alert-danger' : '',
                    type === LEDGER.ADJUSTMENT ? 'alert-warning' : ''
                  ].join(' ')}
                  style={{
                    fontStyle: moment(date).isAfter(moment()) ? 'italic' : ''
                  }}
                >
                  <Td fontSize='xs' p={2}>
                    {(note || refundReason) && (
                      <IconButton
                        aria-label='See More'
                        as={MagnifyingGlassPlusIcon}
                        w='8'
                        h='4'
                        variant='ghost'
                        onClick={() => {
                          setIsLedgerDetailModalOpen(true)
                          setLedgerDetailModalData({
                            note,
                            refundReason
                          })
                          setLedgerDetailModalHeader(startCase(type))
                        }}
                      ></IconButton>
                    )}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {date}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {availableOn}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {formatMoney(amount, 2, '$')}
                  </Td>
                  {canRefund && (
                    <Td fontSize='xs' p={2}>
                      {type === LEDGER.PAYMENT && status === 'succeeded' && (
                        <IconButton
                          w='8'
                          h='4'
                          aria-label='Refund'
                          variant='ghost'
                          onClick={() => {
                            setRefundInfo({
                              amount,
                              address,
                              date,
                              paymentId: id
                            })
                            setIsRefundPaymentModalOpen(true)
                          }}
                        >
                          <Icon as={ReceiptRefundIcon} />
                        </IconButton>
                      )}
                    </Td>
                  )}
                  <Td fontSize='xs' p={2}>
                    <Link
                      to={{
                        pathname: buildiumId
                          ? `https://upandup.managebuilding.com/manager/app/rentroll/${rentals_by_pk.buildium_lease_id}/financials/ledger?isByAccountView=0&isByDateView=1#_journalId_${buildiumId}`
                          : `https://dashboard.stripe.com/payments/${stripeId}`
                      }}
                      target='_blank'
                    >
                      {startCase(type)}
                    </Link>
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {startCase(liabilityLabel)}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {user && (
                      <Link to={{ pathname: `/users/${user?.id}` }} target='_blank'>
                        {user?.first_name} {user?.last_name}
                      </Link>
                    )}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    <span
                      style={{
                        textDecoration: isInitiallyCleared(status, lifecycle) ? 'underline' : 'none'
                      }}
                      id={`status-ic-${id}`}
                    >
                      {startCase(isInitiallyCleared(status, lifecycle) ? lifecycle : status)}
                    </span>
                    {isInitiallyCleared(status, lifecycle) && (
                      <UncontrolledTooltip placement='top' target={`status-ic-${id}`}>
                        <div>We have a high degree of certainty that this payment succeeded.</div>
                        <div style={{ whiteSpace: 'nowrap' }}>
                          Status:&nbsp;<b>{startCase(status)}</b>
                        </div>
                        {initiatedAt && (
                          <div>
                            Initiated:&nbsp;
                            <b>{new Date(initiatedAt).toString()}</b>
                          </div>
                        )}
                      </UncontrolledTooltip>
                    )}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {paymentMethod?.toUpperCase() ?? '-'}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {formatMoney(balance, 2, '$')}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {canEditEntry(date) && (
                      <EditButton
                        rentalId={rentalId}
                        ledgerItem={{
                          id,
                          user,
                          amount,
                          availableOn,
                          date,
                          paymentMethod,
                          liabilityLabel,
                          type,
                          status,
                          lifecycle,
                          initiatedAt,
                          note,
                          refundReason,
                          liabilityTypeId,
                          creditType
                        }}
                      />
                    )}
                  </Td>
                  <Td fontSize='xs' p={2}>
                    {canEditLedger && (
                      <DeleteButton
                        rentalId={rentalId}
                        ledgerItem={{
                          id,
                          amount,
                          type,
                          liabilityLabel,
                          date
                        }}
                      />
                    )}
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <>
        {canCreateLiabilities && (
          <CreateRentalLiabilityModal
            rentalId={rentalId}
            occupancyDate={rental.occupancy_date}
            finalLiabilityDate={rental.final_liability_date}
            close={() => setIsCreateRentalLiabilityModalOpen(false)}
            isOpen={isCreateRentalLiabilityModalOpen}
          />
        )}
        {canIssueRentCredits && (
          <IssueCreditModal
            rentalId={rentalId}
            close={() => setIsIssueCreditModalOpen(false)}
            isOpen={isIssueCreditModalOpen}
          />
        )}
        {canRefund && refundInfo && (
          <IssuePaymentRefundModal
            close={() => setIsRefundPaymentModalOpen(false)}
            isOpen={isRefundPaymentModalOpen}
            refundInfo={refundInfo}
          />
        )}
        <BackfillModal
          rentalId={rental.id}
          close={() => setIsBackfillModalOpen(false)}
          isOpen={isBackfillModalOpen}
        />
        <LedgerDetailModal
          close={() => setIsLedgerDetailModalOpen(false)}
          isOpen={isLedgerDetailModalOpen}
          data={ledgerDetailModalData}
          header={ledgerDetailModalHeader}
        />
      </>
    </>
  )
}

const isInitiallyCleared = (status: string, lifecycle: string | undefined) =>
  [`${PAYMENT_STATUSES.PENDING}`, `${PAYMENT_STATUSES.PROCESSING}`].includes(status) &&
  lifecycle === PAYMENT_LIFECYCLE.INITIALLY_CLEARED
