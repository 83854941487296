"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POST_UNDERWRITE_STATUSES = exports.PRE_AGREEMENT_STATUSES = exports.RENTAL_APPLICATION_STATUSES = exports.RENTAL_APPLICATION_CANCELATION_REASONS = exports.getDepositMultipleForRentalProgramType = exports.QUALIFICATION_FLAGS = exports.DEPOSIT_MULTIPLES = exports.CREDIT_CUTOFFS = void 0;
const rentals_1 = require("./rentals");
exports.CREDIT_CUTOFFS = {
    MINIMUM_CREDIT_SCORE: 620,
    MINIMUM_CREDIT_SCORE_WITH_ADDITIONAL_DEPOSIT: 600
};
exports.DEPOSIT_MULTIPLES = {
    ADDITIONAL_DEPOSIT_RENT_MULTIPLE: 3,
    STANDARD_DEPOSIT_RENT_MULTIPLE: 2,
    VANILLA_ADDITIONAL_DEPOSIT_RENT_MULTIPLE: 2,
    VANILLA_STANDARD_DEPOSIT_RENT_MULTIPLE: 1
};
exports.QUALIFICATION_FLAGS = {
    CRIMINAL_HISTORY: 'criminal_history',
    EVICTION_HISTORY: 'eviction_history',
    CRIMINAL_REPORT_OUTDATED: 'criminal_report_outdated',
    EVICTION_REPORT_OUTDATED: 'eviction_report_outdated'
};
const getDepositMultipleForRentalProgramType = (rentalProgramType) => {
    switch (rentalProgramType) {
        case rentals_1.RENTAL_PROGRAM_TYPES.VANILLA:
            return {
                standardDepositMultiple: exports.DEPOSIT_MULTIPLES.VANILLA_STANDARD_DEPOSIT_RENT_MULTIPLE,
                additionalDepositMultiple: exports.DEPOSIT_MULTIPLES.VANILLA_ADDITIONAL_DEPOSIT_RENT_MULTIPLE
            };
        case rentals_1.RENTAL_PROGRAM_TYPES.UPANDUP:
        default:
            return {
                standardDepositMultiple: exports.DEPOSIT_MULTIPLES.STANDARD_DEPOSIT_RENT_MULTIPLE,
                additionalDepositMultiple: exports.DEPOSIT_MULTIPLES.ADDITIONAL_DEPOSIT_RENT_MULTIPLE
            };
    }
};
exports.getDepositMultipleForRentalProgramType = getDepositMultipleForRentalProgramType;
exports.RENTAL_APPLICATION_CANCELATION_REASONS = Object.freeze({
    ADVERSE_RENTAL_HISTORY: 'adverse_rental_history',
    APPLICATION_INCOMPLETE: 'application_incomplete',
    CANNOT_AFFORD_MOVE_IN_COST: 'cannot_afford_move_in_cost',
    CREDIT_REPORT: 'credit_report',
    CRIMINAL_REPORT: 'criminal_report',
    DOCUMENTS_REJECTED: 'documents_rejected',
    DUPLICATE_APPLICATION: 'duplicate_application',
    FAILED_UNDERWRITING: 'failed_underwriting',
    FOUND_OTHER_HOME: 'found_other_home',
    HOME_FAILED_INSPECTION: 'home_failed_inspection',
    HOME_OFF_MARKET: 'home_off_market',
    HOME_RENTED: 'home_rented',
    INCOME_INSUFFICIENT: 'income_insufficient',
    LEASE_TERM: 'lease_term',
    LOST_BID: 'lost_bid',
    MOVE_IN_TIMELINE: 'move_in_timeline',
    NOT_INTERESTED_IN_PROGRAM_TERMS: 'not_interested_in_program_terms',
    PET_FEES: 'pet_fees',
    UNRESPONSIVE: 'unresponsive',
    TEST: 'test'
});
exports.RENTAL_APPLICATION_STATUSES = Object.freeze({
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    HOLD_FOR_NEW_LEASE: 'hold_for_new_lease',
    PENDING_APPLICATION_REVIEW: 'pending_application_review',
    PENDING_CUSTOMER_INFORMATION: 'pending_customer_information',
    PENDING_CUSTOMER_PAYMENT: 'pending_customer_payment',
    PENDING_CUSTOMER_RENTAL_AGREEMENT_COMPLETION: 'pending_customer_rental_agreement_completion',
    PENDING_PROPERTY_PURCHASE: 'pending_property_purchase',
    PENDING_RENTAL_AGREEMENT_CREATION: 'pending_rental_agreement_creation',
    PENDING_UNDERWRITING: 'pending_underwriting',
    PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION: 'pending_upandup_rental_agreement_completion',
    PENDING_ADDITIONAL_UNDERWRITING_INFORMATION: 'pending_additional_underwriting_information',
    PENDING_ADDITIONAL_LEASE_INFORMATION: 'pending_additional_lease_information'
});
const { PENDING_CUSTOMER_INFORMATION, PENDING_APPLICATION_REVIEW, PENDING_UNDERWRITING, PENDING_ADDITIONAL_UNDERWRITING_INFORMATION, PENDING_RENTAL_AGREEMENT_CREATION, PENDING_CUSTOMER_RENTAL_AGREEMENT_COMPLETION, PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION, PENDING_CUSTOMER_PAYMENT, PENDING_PROPERTY_PURCHASE, PENDING_ADDITIONAL_LEASE_INFORMATION, COMPLETE, CANCELED } = exports.RENTAL_APPLICATION_STATUSES;
exports.PRE_AGREEMENT_STATUSES = [
    PENDING_CUSTOMER_INFORMATION,
    PENDING_APPLICATION_REVIEW,
    PENDING_UNDERWRITING,
    PENDING_ADDITIONAL_UNDERWRITING_INFORMATION,
    PENDING_RENTAL_AGREEMENT_CREATION,
    PENDING_ADDITIONAL_LEASE_INFORMATION
];
// all other statuses are considered post underwrite
exports.POST_UNDERWRITE_STATUSES = [
    PENDING_CUSTOMER_RENTAL_AGREEMENT_COMPLETION,
    PENDING_UP_AND_UP_RENTAL_AGREEMENT_COMPLETION,
    PENDING_CUSTOMER_PAYMENT,
    PENDING_PROPERTY_PURCHASE,
    COMPLETE,
    CANCELED
];
