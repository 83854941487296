import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import { useMutation } from 'urql'

import { Button, Input, ErrorText } from 'components/Toolkit'
import LiabilityTypesDropdown from 'screens/RentRoll/Details/Dropdowns/LiabilityTypesDropdown'
import {
  Upup_CreateRentalAddOnDocument as CreateRentalAddOn,
  Upup_LiabilityTypesQuery as LiabilityTypesQuery
} from 'graphql/generated'
import { payments } from '@homevest/utils'

type LiabilityType = LiabilityTypesQuery['liability_types'][number]

CreateRentalAddOnForm.propTypes = {
  onSubmit: PropTypes.func,
  rentalId: PropTypes.string.isRequired
}

export default function CreateRentalAddOnForm({
  onSubmit = () => {},
  rentalId,
  occupancyDate
}: {
  onSubmit: () => void
  rentalId: string
  occupancyDate: string
}) {
  const [liabilityType, setLiabilityType] = useState<LiabilityType | null>(null)
  const [price, setPrice] = useState('')
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [isSaving, setIsSaving] = useState(false)

  const [{ fetching, error }, createRentalAddOn] = useMutation(CreateRentalAddOn)

  const isValid =
    startDate &&
    moment(startDate).isValid() &&
    price &&
    liabilityType &&
    occupancyDate &&
    moment(occupancyDate).isValid() &&
    moment(startDate).isSameOrAfter(occupancyDate)

  const submitRentalAddOn = async () => {
    if (!isValid) {
      return
    }

    const confirmed = window.confirm(`Are you sure you want to create the rental add on?`)

    if (!confirmed) {
      return
    }

    setIsSaving(true)
    const result = await createRentalAddOn({
      liability_type_id: liabilityType!.id,
      rental_id: rentalId,
      start_date: startDate,
      price: Number(price)
    })

    setIsSaving(false)
    if (result.error === undefined) {
      setLiabilityType(null)
      setPrice('')
      setStartDate('')
      onSubmit()
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <LiabilityTypesDropdown
          onSelect={(lType) => {
            setLiabilityType(lType)
            if (lType.price) {
              setPrice(`${lType.price}`)
            }
          }}
          filter={(lt: any) => {
            return lt.payment_category === payments.PAYMENT_CATEGORIES.ADD_ON
          }}
          value={liabilityType}
          disabled={fetching || isSaving}
        />
        <Input
          label='Price'
          type='number'
          onChange={setPrice}
          value={price}
          placeholder='Enter the amount of the liability'
          required
          min='0'
          hasError={Number(price) <= 0}
          disabled={isSaving || fetching || !liabilityType?.is_variable}
        />
        <Input
          label='Start date'
          type='date'
          onChange={setStartDate}
          value={startDate}
          placeholder='Enter the start date for the add on'
          required
          disabled={fetching || isSaving}
          hasError={!startDate}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={submitRentalAddOn}
            disabled={!isValid || fetching || isSaving}
          >
            Create Rental Add-On
          </Button>
          {error && <ErrorText>{error.message}</ErrorText>}
        </div>
      </React.Suspense>
    </>
  )
}
