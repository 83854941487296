import React, { FC, useCallback, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Spinner,
  useDisclosure
} from '@chakra-ui/react'
import { TrashIcon } from 'components/TailwindUIToolkit/StyledComponents'
import { useDeleteRecurringCreditMutation } from 'graphql/generated'

interface DeleteButtonProps {
  recurringCreditId: string
}
export const DeleteButton: FC<React.PropsWithChildren<DeleteButtonProps>> = ({
  recurringCreditId
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [_rc, deleteRecurringCredit] = useDeleteRecurringCreditMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const handleDeletion = useCallback(async () => {
    setIsDeleting(true)
    await deleteRecurringCredit({
      recurringCreditId: recurringCreditId
    })
  }, [deleteRecurringCredit, recurringCreditId])

  return (
    <>
      <IconButton
        size='xs'
        disabled={isDeleting}
        aria-label={`Delete recurring credit`}
        icon={isDeleting ? <Spinner /> : <TrashIcon />}
        onClick={onOpen}
      />
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Recurring Credit
            </AlertDialogHeader>
            <AlertDialogFooter>
              <Button isDisabled={isDeleting} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button isDisabled={isDeleting} colorScheme='red' ml={3} onClick={handleDeletion}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
