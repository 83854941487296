import { IconButton } from '@chakra-ui/react'
import { Pencil } from 'components/Toolkit'
import { LedgerItem } from 'lib/ledger'
import React, { FC, useState } from 'react'
import { EditLedgerItemModal } from './EditModal'

interface EditButtonProps {
  ledgerItem: LedgerItem
  rentalId: string
}
export const EditButton: FC<React.PropsWithChildren<EditButtonProps>> = ({
  rentalId,
  ledgerItem
}) => {
  const [isOpen, setIsOpen] = useState(false)

  if (ledgerItem.type === 'Payment') {
    return null
  }
  return (
    <>
      <IconButton
        w='8'
        h='4'
        variant='ghost'
        disabled={isOpen}
        aria-label={`Edit ${ledgerItem.type}`}
        icon={<Pencil />}
        onClick={() => setIsOpen(true)}
      />
      <EditLedgerItemModal
        ledgerItem={ledgerItem}
        rentalId={rentalId}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}
