import React, { useState } from 'react'

import { capabilities } from '@homevest/utils'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { BodyText, HeaderText, ErrorText, SuccessText } from 'components/Toolkit'
import Dropzone from 'components/Dropzone'

import { hasCapability } from 'lib/admin-perms'

import { generateAuthorizationHeader } from 'lib/servicing-api'

const { DAGGER } = capabilities.CAPABILITY_TYPES

const StyledContent = styled.div`
  width: 500px;
  margin: 0 auto;
`

export default function GlTransactionsUploader() {
  const isDagger = useSelector((store) => hasCapability(store.admin, DAGGER))

  if (!isDagger) {
    return <ErrorText>You do not have the capability of DAGGER.</ErrorText>
  }

  return (
    <StyledContent>
      <HeaderText size='h3' style={{ textAlign: 'center' }}>
        General Ledger Transaction Uploader
      </HeaderText>
      <React.Suspense fallback={<Spinner color='primary' />}>
        <GlTransactionsUploaderViewer />
      </React.Suspense>
    </StyledContent>
  )
}

function GlTransactionsUploaderViewer() {
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)

  const admin = useSelector((store) => store.admin)

  const handleSuccess = () => {
    setSuccessMessage(`File uploaded to GCS!`)
  }

  if (isLoading) {
    return <Spinner color='primary' style={{ textAlign: 'center' }} />
  }

  if (successMessage) {
    return (
      <div>
        <SuccessText>
          <div dangerouslySetInnerHTML={{ __html: successMessage }} />
        </SuccessText>
      </div>
    )
  }

  return (
    <div>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <BodyText style={{ marginBottom: '20px' }}>
        This tool uploads a GL transactions file from Buildium for the Statements Airflow.
      </BodyText>
      <div style={{ marginTop: '20px' }}>
        <Dropzone
          onSuccess={handleSuccess}
          uploadPath='/buildium_staging/general_ledger_consolidated.csv'
          uploadToHomevestData={true}
        />
      </div>
    </div>
  )
}
