export const apiUrl = process.env.REACT_APP_API_ENDPOINT!

export const cookieOptions =
  process.env.NODE_ENV! === 'production'
    ? {
        secure: true,
        domain: 'upandup.co'
      }
    : {}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API!,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH!,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DB!,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT!,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_BUCKET!
}

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN! || undefined

export const graphqlConfig = {
  serverURL: process.env.REACT_APP_GQL_ENDPOINT!,
  websocketURL: process.env.REACT_APP_GQL_WEBSOCKET!
}

export const webUrl = process.env.REACT_APP_IMPERSONATE_ENDPOINT!
export const mortgageEngineUrl = process.env.REACT_APP_MORTGAGE_ENGINE_ENDPOINT!
export const bulkJournalEntryUploaderUrl =
  process.env.REACT_APP_BULK_JOURNAL_ENTRY_UPLOADER_ENDPOINT!
export const bulkJournalEntryValidatorUrl =
  process.env.REACT_APP_BULK_JOURNAL_ENTRY_VALIDATOR_ENDPOINT!
export const renowalkUrl = process.env.REACT_APP_RENOWALK_WEB!

export const googleApiKey = 'AIzaSyAllvPSGOH0_q2zk3bCS-swGeD0g1LPu98'

export const rentRollLedgerLockDate = process.env.REACT_APP_LEDGER_LOCK_DATE

export const fmvEstimateUploadUrl = process.env.REACT_APP_FMV_ESTIMATE_UPLOAD_ENDPOINT!
export const fmvMarkPropertiesUrl = process.env.REACT_APP_MARK_PROPERTIES_FMV_ENDPOINT!
