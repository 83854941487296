import React from 'react'
import { Text } from '@chakra-ui/react'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { getLeaseLedgerUrl, getLeasePaymentSettingsUrl } from 'constants/buildium'

export default function Buildium({ buildiumLeaseId }: { buildiumLeaseId?: number | null }) {
  return (
    <ContentSectionCard padding title={'Buildium'}>
      {buildiumLeaseId ? (
        <>
          {(() => {
            const ledgerUrl = getLeaseLedgerUrl(buildiumLeaseId)
            const paymentSettingsUrl = getLeasePaymentSettingsUrl(buildiumLeaseId)
            return (
              <div>
                <Text>
                  <a href={ledgerUrl} target='_blank' rel='noopener noreferrer'>
                    🔗 Financial Ledger
                  </a>
                  <br />
                  <a href={paymentSettingsUrl} target='_blank' rel='noopener noreferrer'>
                    🔗 Payment Settings
                  </a>
                </Text>
              </div>
            )
          })()}
        </>
      ) : (
        <div>
          <Text>No Buildium Lease!</Text>
        </div>
      )}
    </ContentSectionCard>
  )
}
