import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton
} from '@chakra-ui/react'
import { capabilities } from '@homevest/utils'
import { StoreState } from 'store'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { RecurringCreditsFragment } from 'graphql/generated'
import { formatMoney } from 'lib/numbers'
import { hasCapability } from 'lib/admin-perms'
import { DeleteButton } from './IssueCreditModal/RecurringCreditsDeleteButton'
import IssueRecurringCreditForm from './IssueCreditModal/IssueRecurringCreditForm'

const { LEDGER_RENT_CREDIT_ISSUER } = capabilities.CAPABILITY_TYPES

export default function RecurringCredits({
  rental
}: {
  rental: RecurringCreditsFragment & {
    id: string
  }
}) {
  const { recurring_credits } = rental

  const [isCreateRecurringCreditModalOpen, setIsCreateRecurringCreditModalOpen] = useState(false)
  const admin = useSelector<StoreState, any>((state) => state.admin)
  const canCreateRentCredits = hasCapability(admin, LEDGER_RENT_CREDIT_ISSUER)

  return (
    <ContentSectionCard
      title={'Recurring Credits'}
      action={
        canCreateRentCredits ? (
          <Button
            size='xs'
            colorScheme='teal'
            onClick={(e) => {
              e.stopPropagation()
              setIsCreateRecurringCreditModalOpen(true)
            }}
          >
            + Recurring Credit
          </Button>
        ) : undefined
      }
    >
      {recurring_credits.length > 0 && (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Start Date</Th>
                <Th># Months</Th>
                <Th>Total Amount</Th>
                <Th>Monthly Amount</Th>
                <Th>Liability Type</Th>
                <Th>Notes</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {recurring_credits.map((recurring_credit) => (
                <Tr key={recurring_credit.id}>
                  <Td>{recurring_credit.start_date}</Td>
                  <Td>{recurring_credit.num_months}</Td>
                  <Td>{formatMoney(recurring_credit.total_amount, 2, '$')}</Td>
                  <Td>
                    {formatMoney(
                      Number(
                        (
                          Number(recurring_credit.total_amount) / recurring_credit.num_months
                        ).toFixed(2)
                      ),
                      2,
                      '$'
                    )}
                  </Td>
                  <Td>{recurring_credit.liability_type.name}</Td>
                  <Td>{recurring_credit.notes}</Td>
                  <Td>
                    <DeleteButton recurringCreditId={recurring_credit.id} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {canCreateRentCredits && (
        <Modal
          isOpen={isCreateRecurringCreditModalOpen}
          onClose={() => setIsCreateRecurringCreditModalOpen(false)}
        >
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>Create a Recurring Credit</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <IssueRecurringCreditForm
                  onSubmit={() => setIsCreateRecurringCreditModalOpen(false)}
                  rentalId={rental.id}
                />
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
    </ContentSectionCard>
  )
}
